import useCustomToast from "@/src/components/CustomToast";
import Loader from "@/src/components/Loader/Loader";
import { Button } from "@/src/components/ui/button";
import { ScrollArea } from "@/src/components/ui/scroll-area";
import {
	GetPatientMessageConversationsSlice,
	SendPatientMessageConversationSlice,
} from "@/src/store/slices/scheduleSlice";
import useMessageStore from "@/src/store/useMessageStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import React, { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePusherMessages } from "../hooks/usePusherMessages";
import { cn, getInitials } from "@/src/utils/general";
import { Input } from "@/src/components/ui/input";
import { z } from "zod";

export const SendMessageSchema = z.object({
	message: z.string().min(1, "Message cannot be empty"),
	appointment_code: z.string(),
});

export type SendMessageType = z.infer<typeof SendMessageSchema>;

const ScheduleChatTab: React.FC<{ customer: any; track_code: string }> = ({
	customer,
	track_code,
}) => {
	const scrollAreaRef = React.useRef<HTMLDivElement>(null);
	const { messages, addMessage, setMessages } = useMessageStore();
	const getPatientMessagesQuery =
		GetPatientMessageConversationsSlice(track_code);
	const customToast = useCustomToast();
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const {
		mutate: mutateSendPatientMessage,
		isPending: isSendingPatientMessage,
	} = SendPatientMessageConversationSlice();

	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid },
		setFocus,
	} = useForm<SendMessageType>({
		resolver: zodResolver(SendMessageSchema),
		defaultValues: {
			message: "",
			appointment_code: track_code,
		},
		mode: "onChange",
	});

	React.useEffect(() => {
		setFocus("message");
	}, [setFocus]);

	const handleNewIncomingMessage = React.useCallback(
		(data) => {
			addMessage(data.message);
		},
		[addMessage]
	);

	usePusherMessages(
		`chat-with-business.${customer.id}.${customer.business_id}`,
		handleNewIncomingMessage
	);

	React.useEffect(() => {
		if (getPatientMessagesQuery?.data?.data) {
			setMessages(getPatientMessagesQuery?.data?.data);
		}
	}, [getPatientMessagesQuery.data, setMessages]);

	const scrollToBottom = React.useCallback(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	}, []);

	React.useEffect(() => {
		scrollToBottom();
	}, [messages, scrollToBottom]);

	const onSubmit: SubmitHandler<SendMessageType> = (data) => {
		mutateSendPatientMessage(data, {
			onSuccess: () => {
				reset({ message: "", appointment_code: track_code });
				setTimeout(() => {
					setFocus("message");
				}, 0);
			},
			onError: (error) => {
				customToast("Message could not be sent 🤕", {
					id: "send-message-patient",
					type: "error",
				});
				console.error(error);
			},
		});
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSubmit(onSubmit)();
		}
	};

	return (
		<section className="mx-auto">
			{getPatientMessagesQuery.isLoading ? (
				<div className="flex h-[400px] w-full items-center justify-center">
					<Loader size={24} />
				</div>
			) : (
				<>
					<ScrollArea
						ref={scrollAreaRef}
						className="mt-3 h-[calc(50vh-100px)] rounded-xl bg-white shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.15)] md:h-[400px]"
					>
						<ul className="flex flex-col space-y-4 p-4">
							{messages
								?.slice()
								?.reverse()
								.map((item, idx) => (
									<IndividualMessage key={idx} {...item} />
								))}
						</ul>
						<div ref={messagesEndRef} />
					</ScrollArea>
					<form
						className="mt-2.5 flex max-h-[44px] flex-1 justify-between space-x-2.5 rounded-xl py-2.5 pl-5 pr-2.5 shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.25)] outline-none"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Input
							type="text"
							className="h-fit min-h-fit flex-1 rounded-none border-none bg-transparent p-0 text-base focus:shadow-none  focus-visible:ring-0 focus-visible:ring-offset-0  "
							placeholder="Enter your message here"
							autoComplete="off"
							{...register("message")}
							onKeyDown={handleKeyDown}
							disabled={isSendingPatientMessage}
						/>
						<Button
							className="h-8 max-w-[140px] flex-1 self-center rounded-md text-white"
							type="submit"
							disabled={isSendingPatientMessage || !isValid}
						>
							{isSendingPatientMessage ? (
								<Loader size={20} />
							) : (
								"Send"
							)}
						</Button>
					</form>
				</>
			)}
		</section>
	);
};

const IndividualMessage: React.FC<{
	id: string;
	body: string;
	sent_at: string;
	status: string;
	direction: string;
	sender: string;
}> = ({ sent_at, sender, status, body }) => {
	const isMe = sender == "customer";
	return (
		<li
			className={cn("flex size-fit items-start gap-x-1.5", {
				"ml-auto flex flex-row-reverse": isMe,
			})}
		>
			<p
				className={cn(
					"size-fit rounded-xl bg-[#D5D5D5] px-2 py-1 font-semibold text-[#323539]",
					{ "bg-primary text-[#E5E5E7] ": isMe }
				)}
			>
				{getInitials(sender, true)}
			</p>
			<div className="space-y-0.5">
				<p
					className={cn(
						"rounded-xl bg-[#F6F6F7] px-3.5 py-1 tracking-[-0.16px] text-[#323539]",
						{ "bg-[#F1F3FC]": isMe }
					)}
				>
					{body}
				</p>
				<div
					className={cn(
						"flex items-center gap-x-1 text-[10px] tracking-[-1%] text-[#858C95]",
						{ "pl-3.5": !isMe, "justify-end pr-3.5 ": isMe }
					)}
				>
					<p className="capitalize">{status}</p>
					<p className="">|</p>
					<p>{format(new Date(sent_at), "dd/M/yyyy")}</p>
					<p className="">|</p>
					<p className="lowercase">
						{format(new Date(sent_at), "h:mm aa")}
					</p>
				</div>
			</div>
		</li>
	);
};

export default ScheduleChatTab;
