import { create } from "zustand";
import { persist } from "zustand/middleware";
import { WaitlistCodeInfoResponse } from "../types/waitlist";

interface UserStore {
	user: WaitlistCodeInfoResponse | null;
	join_code?: string;
	location_join_code?: string;
	setUser: (user: WaitlistCodeInfoResponse | null) => void;
	setJoinCode: (value: string) => void;
	setLocationJoinCode: (value: string) => void;
	reset: () => void;
}

const initialState = {
	user: null,
	join_code: "",
	location_join_code: "",
};

const useUserStore = create<UserStore, [["zustand/persist", UserStore]]>(
	persist(
		(set) => ({
			...initialState,
			setUser: (user) => {
				if (user)
					set((state) => {
						return { user: { ...state.user, ...user } };
					});
			},
			setJoinCode: (join_code) => set(() => ({ join_code })),
			setLocationJoinCode: (location_join_code) =>
				set(() => ({ location_join_code })),
			reset: () =>
				set((state) => ({
					...initialState,
					join_code: state.join_code,
					location_join_code: state.location_join_code,
				})),
		}),
		{
			name: "user-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useUserStore;
