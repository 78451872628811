import { create } from "zustand";
import { persist, createJSONStorage, StateStorage } from "zustand/middleware";

export interface Message {
	id: string;
	body: string;
	sent_at: string;
	sender: string;
	direction: string;
	status: string;
}

interface MessageStoreState {
	messages: Message[];
	addMessage: (newMessage: Message) => void;
	setMessages: (initialMessages: Message[]) => void;
	clearMessages: () => void;
}

const customStorage: StateStorage = {
	getItem: (name) => {
		const str = sessionStorage.getItem(name);
		if (!str) return null;
		return str;
	},
	setItem: (name, value) => {
		sessionStorage.setItem(name, value);
	},
	removeItem: (name) => {
		sessionStorage.removeItem(name);
	},
};

const useMessageStore = create<MessageStoreState>()(
	persist(
		(set) => ({
			messages: [],
			addMessage: (newMessage: Message) =>
				set((state) => {
					if (
						state.messages.some((msg) => msg.id === newMessage.id)
					) {
						return state;
					}

					const sortedMessages = [...state.messages, newMessage].sort(
						(a, b) =>
							new Date(b.sent_at).getTime() -
							new Date(a.sent_at).getTime()
					);

					return { messages: sortedMessages };
				}),
			setMessages: (initialMessages: Message[]) =>
				set(() => {
					const sortedMessages = [...initialMessages].sort(
						(a, b) =>
							new Date(b.sent_at).getTime() -
							new Date(a.sent_at).getTime()
					);

					return { messages: sortedMessages };
				}),
			clearMessages: () => set({ messages: [] }),
		}),
		{
			name: "message-store",
			version: 1,
			storage: createJSONStorage(() => customStorage),
			partialize: (state) => ({ messages: state.messages }),
		}
	)
);

export default useMessageStore;
