import React from "react";
import { Outlet } from "react-router";
import { changeTheme } from "../utils/general";

const Layout: React.FC = () => {
	const theme = localStorage.getItem("theme");
	React.useLayoutEffect(() => {
		if (theme) {
			if (theme == "default") {
				changeTheme("#176199");
			} else {
				changeTheme(theme);
			}
		}
	}, [theme]);

	return (
		<main className="relative flex min-h-[100dvh] flex-col justify-between bg-[#F3F3F3]">
			<Outlet />
			<footer className="sticky bottom-0 flex items-center justify-center space-x-2 bg-[#F3F3F3] py-2">
				<small className="py-0.5 text-[12px] text-[#323539]">
					Powered by
				</small>
				<img
					src="/assets/images/brand/logo-blue-footer.svg"
					alt="Migranium logo"
					className="py-0.5"
				/>
			</footer>
		</main>
	);
};

export default Layout;
