import { useGetWaitlistInfo } from "@/src/store/slices/waitlist";
import useUserStore from "@/src/store/useUserStore";
import { cn } from "@/src/utils/general";
import { Badge } from "../ui/badge";
import CheckInModal from "./CheckInWaitlistModal";
import LeaveWaitlistModal from "./LeaveWaitlistModal";
import RequestSwap from "./RequestSwap";

const ViewWaitListDetails: React.FC = () => {
	const getWaitlistInfoQuery = useGetWaitlistInfo();
	const status = getWaitlistInfoQuery?.data?.data.waitlist_info.status;
	const { user } = useUserStore((s) => ({
		user: s.user,
	}));

	const estimatedWaitTime = {
		hour: Math.floor(parseInt(user?.estimated_wait_time ?? "") / 60),
		minute: parseInt(user?.estimated_wait_time ?? "") % 60,
	};

	return (
		<div className="mt-5 flex flex-col">
			<div className="flex items-center justify-between space-x-2 px-6">
				<i className="mgc_group_2_line before:!text-primary" />
				<div className="flex flex-1 items-center justify-between">
					<p className="capitalize">
						{user?.waitlist_info?.customer?.last_name}{" "}
						{user?.waitlist_info?.customer?.first_name}
					</p>
					<Badge
						className={cn(
							"rounded-md px-1.5 py-0 text-xxs font-medium capitalize leading-[22px] tracking-[-0.1px]",
							{
								"bg-[#F4E2EC] text-[#B7241D]":
									status === "waiting_to_be_approved",
								"bg-[#ABDBF7] text-[#053969]":
									status === "accepted",
								"bg-[#F7EDD4] text-[#896200]":
									status === "next_to_serve",
								"bg-[#B5EAC8] text-[#086E17]":
									status === "getting_service",
							}
						)}
					>
						{getWaitlistInfoQuery?.data?.data.waitlist_info.status
							.split("_")
							.join(" ")}
					</Badge>
				</div>
			</div>
			<div className="mt-4 flex flex-col items-center px-6 pb-8">
				{!!getWaitlistInfoQuery?.data?.data.waitlist_setting
					.show_customer_counter && (
					<div className="flex flex-col items-center">
						<div className="mx-auto flex flex-col items-center">
							<h3 className="p-2.5 text-sm font-bold text-[#6D748D]">
								My Position
							</h3>
							<h1 className="text-[100px] font-[800] capitalize leading-none text-primary">
								{user?.position}
							</h1>
						</div>
						<RequestSwap />
					</div>
				)}
				{(getWaitlistInfoQuery?.data?.data.waitlist_setting
					.show_customer_waiting_message ||
					!getWaitlistInfoQuery?.data?.data.waitlist_setting
						.show_customer_counter) && (
					<p
						className={cn(
							"text-base leading-4 tracking-[-0.2px] text-main-7",
							{
								"pb-1 pt-4":
									getWaitlistInfoQuery?.data?.data
										.waitlist_setting.show_customer_counter,
								"pb-9 pt-20":
									getWaitlistInfoQuery?.data?.data
										.waitlist_setting
										.show_customer_waiting_message ||
									!getWaitlistInfoQuery?.data?.data
										.waitlist_setting.show_customer_counter,
								"py-20":
									!getWaitlistInfoQuery?.data?.data
										.waitlist_setting
										.show_customer_counter ||
									!getWaitlistInfoQuery?.data?.data
										.waitlist_setting
										.show_customer_waiting_time,
							}
						)}
					>
						{
							getWaitlistInfoQuery?.data?.data.waitlist_setting
								.customer_waiting_message
						}
					</p>
				)}
				{!!getWaitlistInfoQuery?.data?.data.waitlist_setting
					.show_customer_waiting_time && (
					<div className="mt-8 flex w-full items-center justify-between rounded-xl bg-[#F1F3FC] py-4 pl-4 pr-6">
						<h3 className="text-primary">Est. Wait Time</h3>
						<ul className="flex items-center text-[34px] font-extrabold text-primary">
							<li className="flex">
								<div className="flex flex-col">
									{estimatedWaitTime.hour
										.toString()
										.padStart(2, "0")}{" "}
									<p className="self-end text-[18px] font-normal">
										hrs
									</p>
								</div>
								<span className="mx-1.5">:</span>
							</li>

							<li className="flex flex-col">
								{estimatedWaitTime.minute
									.toString()
									.padStart(2, "0")}{" "}
								<p className="self-end text-[18px] font-normal">
									mins
								</p>
							</li>
						</ul>
					</div>
				)}
			</div>
			<div className="flex flex-col items-stretch space-y-2 border-t border-t-[#E5E5E7] px-6 py-3">
				<CheckInModal />
				<LeaveWaitlistModal />
				<p className="text-xs leading-[14px] tracking-[-0.2px] text-[#6D748D]">
					Note: Wait times are estimates and may vary. We aim for
					first-come, first-served, but exceptions may occur based on
					specific situations and management&apos;s discretion. Thank
					you for your understanding.
				</p>
			</div>
		</div>
	);
};

export default ViewWaitListDetails;
