import CustomAppointmentSvg from "@/src/pages/Schedule/components/svgs/CustomAppointmentSvg";
import { cn } from "@/src/utils/general";
import React, { ReactNode } from "react";

interface IScheduleLayoutProps {
	children: ReactNode;

	href?: string;
	name?: string | undefined;
	isButtonDisabled?: boolean;
	isSubmitting?: boolean;
	loaderSize?: number;
}

const ScheduleLayout: React.FC<IScheduleLayoutProps> = ({ children }) => {
	return (
		<main className="h-full w-full overflow-hidden">
			<div className="flex h-full flex-col items-center gap-y-12 px-4 md:flex md:gap-x-16  lg:flex-row lg:px-24 ">
				<div className="flex w-full justify-center 2xl:justify-end">
					<CustomAppointmentSvg
						className="h-[150px] w-[200px] md:h-[200px] md:w-[300px] lg:h-[300px] lg:w-[400px]"
						width={100}
						height={100}
					/>
				</div>
				<div className="flex w-full justify-center 2xl:justify-normal">
					{children}
				</div>
			</div>
		</main>
	);
};

export default ScheduleLayout;
