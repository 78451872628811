import React, { Fragment } from "react";
import { createEditor } from "lexical";
import { ListNode, ListItemNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";

interface InfoTextNode {
	type: string;
	children?: InfoTextNode[];
	format?: number | string;
	text?: string;
	tag?: string;
	detail?: number;
	mode?: string;
	style?: string;
	direction?: string;
	indent?: number;
	version?: number;
	listType?: string;
	start?: number;
	value?: number;
}

interface InfoTextEditorState {
	root: {
		children: InfoTextNode[];
		direction?: string;
		format?: string;
		indent?: number;
		type: string;
		version?: number;
	};
}

interface InfoTextRendererProps {
	value: InfoTextEditorState | string;
	className?: string;
}

const InfoTextRenderer: React.FC<InfoTextRendererProps> = ({
	value,
	className = "",
}) => {
	// if (!value) return null;
	React.useEffect(() => {
		if (editorState) {
			editor.setEditorState(
				editor.parseEditorState(JSON.stringify(editorState))
			);
		}
	}, [value]);

	let editorState: InfoTextEditorState;
	try {
		editorState = typeof value === "string" ? JSON.parse(value) : value;
	} catch (e) {
		console.error("Failed to parse editor state:", e);
		return null;
	}

	// Initialize InfoText editor config
	const editor = createEditor({
		namespace: "InfoTextRenderer",
		nodes: [HeadingNode, ListNode, ListItemNode],
		onError: (error: Error) => {
			console.error(error);
		},
	});

	const renderTextContent = (node: InfoTextNode): JSX.Element => {
		const textClassName =
			node.format === 1 ? "font-bold" : node.format === 2 ? "italic" : "";
		return <span className={textClassName}>{node.text}</span>;
	};

	const renderNode = (
		node: InfoTextNode
	): JSX.Element | JSX.Element[] | null => {
		switch (node.type) {
			case "heading":
				return (
					<h3 className="mb-4 text-xl font-bold">
						{node.children?.map((child, index) => (
							<Fragment key={index}>
								{renderTextContent(child)}
							</Fragment>
						))}
					</h3>
				);

			case "list":
				return (
					<ul className="list-disc space-y-2 pl-6">
						{node.children?.map((listItem, index) => (
							<li key={index}>
								{listItem.children?.map(
									(textNode, textIndex) => (
										<Fragment key={textIndex}>
											{renderTextContent(textNode)}
										</Fragment>
									)
								)}
							</li>
						))}
					</ul>
				);

			case "text":
				return renderTextContent(node);

			default:
				if (node.children) {
					return node.children.map((child, index) => (
						<Fragment key={index}>{renderNode(child)}</Fragment>
					));
				}
				return null;
		}
	};

	return (
		<div className={`mx-auto max-w-2xl p-6 ${className}`.trim()}>
			{editorState && renderNode(editorState.root)}
		</div>
	);
};

export default InfoTextRenderer;
