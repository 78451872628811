import pusher from "@/src/configs/PusherConfig";
import React from "react";

export const usePusherMessages = (
	subscriptionId: string,
	onNewMessage: (data) => void
) => {
	const channelRef = React.useRef<any>(null);
	const isBoundRef = React.useRef(false);
	React.useEffect(() => {
		if (!subscriptionId) return;

		if (!channelRef.current) {
			channelRef.current = pusher.subscribe(subscriptionId);
		}

		const channel = channelRef.current;

		const handleNewMessage = (pusherData: any) => {
			// console.log("New message received:", pusherData);
			onNewMessage(pusherData);
		};

		if (!isBoundRef.current) {
			channel.bind("new_message", handleNewMessage);
			isBoundRef.current = true;
		}

		return () => {
			if (channelRef.current) {
				channelRef.current.unbind("new_message", handleNewMessage);
				pusher.unsubscribe(subscriptionId);
				channelRef.current = null; // Reset the ref
				isBoundRef.current = false;
			}
		};
	}, [subscriptionId, onNewMessage]);
};
