import { Dialog, DialogContent, DialogTitle } from "@/src/components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";

interface AdditionalTimeModal {
	isOpen: boolean;
	onClose: () => void;
	onAddTime: (minutes: number) => void;
}

const AdditionalTimeModal = ({
	isOpen,
	onClose,
	onAddTime,
}: AdditionalTimeModal) => {
	return (
		<Dialog open={isOpen}>
			<DialogContent className="w-full max-w-[85%] rounded-lg bg-none p-0 md:w-auto ">
				<DialogTitle className="sr-only">Time addition</DialogTitle>
				<DialogDescription className="sr-only">
					Add additional 10 minutes to timer
				</DialogDescription>
				<div className="rounded-lg bg-white p-6 shadow-xl">
					<h2 className="mb-4 text-xl font-bold">Time Running Low</h2>
					<p className="mb-4">
						Your timer is almost finished. Would you like to add
						more time?
					</p>
					<div className="flex flex-col justify-center gap-2 md:flex-row md:justify-end">
						<button
							onClick={onClose}
							className="rounded bg-gray-200 px-4 py-2 text-gray-800 hover:bg-primary/50"
						>
							No, Continue
						</button>
						<button
							onClick={() => {
								onAddTime(10);
							}}
							className="rounded bg-primary px-4 py-2 text-white hover:bg-primary/50"
						>
							Add 10 Minutes
						</button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default AdditionalTimeModal;
