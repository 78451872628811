import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import { cn } from "@/src/utils/general";
import { ReactNode } from "react";

interface FormLayoutProps {
	children: ReactNode;
}

const FormLayout = ({ children }: FormLayoutProps) => {
	const { step, setStep, bookingType } = useScheduleStore();

	const handleGoPreviousStep = () => {
		if (bookingType === "business") {
			setStep(1.5);
		} else if (bookingType === "station") {
			setStep(0);
		} else {
			setStep(Math.max(0, step - 1));
		}
	};
	// const handleGoPreviousStep = () => {

	// 	if (bookingType == "station") {
	// 		setStep(0);
	// 	} else {
	// 		setStep(step - 1);
	// 	}
	// };
	return (
		<div className="mx-auto flex w-full max-w-[2000px] flex-col gap-y-4">
			<div className=" flex w-full justify-between bg-white font-sans">
				<div className="flex flex-1 items-center border-b-2 border-[#053969] py-5 lg:pl-12">
					<button
						type="button"
						onClick={handleGoPreviousStep}
						className="h-fit w-fit cursor-pointer p-0"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</button>

					<button
						className="flex w-full justify-center text-center text-[15px] font-semibold text-primary md:text-lg"
						onClick={() => setStep(1)}
					>
						<h1>Time & Date</h1>
					</button>
				</div>
				<div
					className={cn(
						"flex flex-1 items-center border-b-2 border-[#053969] py-5 pr-6 lg:pr-12"
					)}
				>
					<Button
						className={cn(
							"flex h-fit w-full justify-center p-0 text-center text-[15px] text-main-1 hover:bg-transparent hover:text-main-1 md:text-lg",
							{
								"font-semibold text-primary": step === 1,
							}
						)}
						onClick={() => setStep(2)}
						disabled={step == 1}
						variant="ghost"
					>
						<h1>Information</h1>
					</Button>
					<button
						onClick={(e) => {
							e.preventDefault();
							if (step !== 1) setStep(2);
						}}
						className="h-fit w-fit rotate-180 cursor-pointer p-0"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</button>
				</div>
			</div>
			<div className="h-full">{children}</div>
		</div>
	);
};

export default FormLayout;
