import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/src/components/ui/dialog";
import { useUpdateWaitlist } from "@/src/store/slices/waitlist";
import {
	UpdateWaitlistActionType,
	UpdateWaitlistSchema,
} from "@/src/types/waitlist";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useCustomToast from "../CustomToast";
import { LoaderButton } from "../ui-extended/loader-button";
import { Button } from "../ui/button";
import useUserStore from "@/src/store/useUserStore";
import { cn } from "@/src/utils/general";

const CheckInModal: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const customToast = useCustomToast();
	const queryClient = useQueryClient();
	const user = useUserStore((s) => s.user);
	const updateWaitlistMutation = useUpdateWaitlist(
		() => {
			!(
				user?.waitlist_info.check_in_status === "to_be_approved" ||
				user?.waitlist_info.check_in_status === "pending"
			)
				? customToast("You have checked in 🎉", {
						id: "update-waitlist",
					})
				: customToast("Undo check in successful 🫡", {
						id: "update-waitlist",
					});
			queryClient.invalidateQueries({ queryKey: ["waitlist-info"] });
			setIsOpen(false);
			reset();
		},
		() => {
			!(
				user?.waitlist_info.check_in_status === "to_be_approved" ||
				user?.waitlist_info.check_in_status === "pending"
			)
				? customToast("Check in unsuccessful 🤕 ", {
						id: "update-waitlist",
						type: "error",
					})
				: customToast("Undo Check in unsuccessful 🤕 ", {
						id: "update-waitlist",
						type: "error",
					});
		}
	);

	const {
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm<UpdateWaitlistActionType>({
		defaultValues: {
			action: !(
				user?.waitlist_info.check_in_status === "to_be_approved" ||
				user?.waitlist_info.check_in_status === "pending"
			)
				? "check_in"
				: "undo_checkin",
		},
		resolver: zodResolver(UpdateWaitlistSchema),
	});

	const onSubmit: SubmitHandler<UpdateWaitlistActionType> = (data) => {
		updateWaitlistMutation.mutate(data);
	};

	React.useEffect(() => {
		if (user)
			setValue(
				"action",
				!(
					user?.waitlist_info.check_in_status === "to_be_approved" ||
					user?.waitlist_info.check_in_status === "pending"
				)
					? "check_in"
					: "undo_checkin"
			);
	}, [user]);

	return (
		<Dialog
			open={isOpen}
			onOpenChange={(state) => {
				if (!updateWaitlistMutation.isPending) {
					setIsOpen(state);
					if (!state) reset();
				}
			}}
		>
			<DialogTrigger className="w-full">
				<Button
					className={cn("w-full", {
						"text-white": !(
							user?.waitlist_info.check_in_status ===
								"to_be_approved" ||
							user?.waitlist_info.check_in_status === "pending"
						),
					})}
					variant={
						user?.waitlist_info.check_in_status === "to_be_approved"
							? "outline-primary"
							: "default"
					}
					onClick={() => setIsOpen(true)}
				>
					{user?.waitlist_info.check_in_status
						? "Undo Check-in"
						: "Check-in"}
				</Button>
			</DialogTrigger>

			<DialogContent className="w-full max-w-[360px] gap-8 rounded-[10px] p-6">
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
					<div className="flex flex-col space-y-2">
						<DialogHeader>
							<DialogTitle className="flex items-center justify-between">
								<h1 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px]">
									{!(
										user?.waitlist_info.check_in_status ===
											"to_be_approved" ||
										user?.waitlist_info.check_in_status ===
											"pending"
									)
										? "Check-in to Location"
										: "Undo Check-in to Location"}
								</h1>
								<DialogClose
									aria-label="Close dialog"
									title="Close dialog"
								>
									<i className="mgc_close_line before:!text-main-4" />
									<span className="sr-only">Close</span>
								</DialogClose>
							</DialogTitle>
						</DialogHeader>
						<p className="text-base tracking-[-0.16px] text-main-1">
							{/* You&apos;re on multiple waitlists. Should we marked
							you as checked-in for all stations or just this one? */}

							{!(
								user?.waitlist_info.check_in_status ===
									"to_be_approved" ||
								user?.waitlist_info.check_in_status ===
									"pending"
							)
								? "Once you're present at the location, please check-in to let the staff know you're here."
								: "Since you're not present at the location, please cancel your check-in to inform the staff."}
						</p>
					</div>

					{errors.swap_direction?.message && (
						<small className="text-sm text-destructive">
							{errors.swap_direction?.message as string}
						</small>
					)}
					<DialogFooter className="flex w-full !flex-row space-x-2.5">
						<Button
							className="flex-1"
							variant="ghost"
							onClick={() => {
								if (!updateWaitlistMutation.isPending) {
									setIsOpen(false);
									reset();
								}
							}}
							disabled={updateWaitlistMutation.isPending}
						>
							Cancel
						</Button>

						<LoaderButton
							className="flex-1 text-white"
							type="submit"
							loaderSize={20}
							onClick={() => handleSubmit(onSubmit)}
							loading={updateWaitlistMutation.isPending}
							disabled={updateWaitlistMutation.isPending}
						>
							Yes
						</LoaderButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default CheckInModal;
