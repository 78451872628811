import { CONFIGS } from "../configs";

export const API_URI = import.meta.env.VITE_BASE_URL;

export const PUSHER_KEY = import.meta.env.VITE_PUSHER_APP_KEY;

export const WAITLIST_ENVIRONMENT_LINK =
	(CONFIGS.ENVIRONMENT as "dev" | "staging" | "production") === "dev"
		? "https://admin.dev.migranium.com"
		: CONFIGS.ENVIRONMENT === "staging"
			? "https://admin.staging.migranium.com"
			: CONFIGS.ENVIRONMENT === "production"
				? "https://admin.migranium.com"
				: "https://admin.dev.migranium.com";

export const SPACES_ENVIRONMENT_LINK =
	(CONFIGS.ENVIRONMENT as "dev" | "staging" | "production") === "dev"
		? "https://spaces.dev.migranium.com"
		: CONFIGS.ENVIRONMENT === "staging"
			? "https://spaces.staging.migranium.com,"
			: CONFIGS.ENVIRONMENT === "production"
				? "https://spaces.migranium.com"
				: "https://spaces.dev.migranium.com";

export const LANDING_ENVIRONMENT_LINK =
	(CONFIGS.ENVIRONMENT as "dev" | "staging" | "production" | "local") ===
	"dev"
		? "https://dev.migranium.com"
		: CONFIGS.ENVIRONMENT === "staging"
			? "https://staging.migranium.com"
			: CONFIGS.ENVIRONMENT === "production"
				? "https://migranium.com"
				: "https://dev.migranium.com";

export type ObjectValues<T> = T[keyof T];

export const AVAILABLE_APPOINTMENT_TIME = [
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:56",
	"10:04",
	"10:12",
	"10:20",
	"10:28",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
];

export const PatientWaitlistStatus = {
	Pending: {
		textColor: "#B7241D",
		backgroundColor: "#F4E2EC",
	},
	Confirmed: {
		textColor: "#053969",
		backgroundColor: "#ABDBF7",
	},
	NextInLine: {
		textColor: "#896200",
		backgroundColor: "#F7EDD4",
	},
	InService: {
		textColor: "#086E17",
		backgroundColor: "#B5EAC8",
	},
};

export const StatusOfPatient = {
	AllStatus: "all_status",
	Pending: "waiting_to_be_approved",
	Confirmed: "accepted",
	NextInLine: "next_to_serve",
	InService: "getting_service",
	Done: "done",
	Cancelled: "cancelled",
} as const;

export const ScheduleStatus: Record<string, string> = {
	waiting_to_be_approved: "pending",
	cancelled_by_customer: "cancelled",
	cancelled_by_admin: "cancelled",
	cancelled: "cancelled",
	approved: "confirmed",
	rescheduled: "rescheduled",
	follow_up: "follow_up",
	expired: "expired",
};

export type StatusOfPatientType = ObjectValues<typeof StatusOfPatient>;

export const InputPatterns = {
	elevenTelephone: /^\d{11}$/,
	tenTelephone: /^\d{10}$/,
	universalTelephone:
		/^\+(?:[1-9]|[1-9][0-9]|1\d{2}|2[0-4][0-9]|25[0-9])\d{10}$/,
	password: /^(?=.*[\W_]).{8,20}$/,
	email: /^([a-z\d.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?(\.[a-z]{2,8})?(\.[a-z]{2,8})?$/,
};

/**
 * An array of country dialing codes.
 *
 * This array contains dialing codes for 249 countries, formatted with a "+" sign
 * followed by the country code. Each object in the array includes the dialing code
 * as both the label and the value.
 *
 * @type {Record<string, string>[]}
 */
export const countryCodes = Array.from({ length: 249 }, (_, i) => {
	return { label: "+" + (i + 1), value: "+" + (i + 1) };
});

export const userTimezone =
	Intl.DateTimeFormat().resolvedOptions().timeZone || "utc";
