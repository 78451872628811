import {
	APIVersion2GetFormInfo,
	APIVersion2SubmitFormResponses,
} from "@/src/http/v2";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGetFormInfo = (form_id: string) => {
	return useQuery({
		queryKey: ["form"],
		queryFn: () => APIVersion2GetFormInfo(form_id),
	});
};

export const useSubmitFormResponse = () => {
	return useMutation({
		mutationFn: ({
			form_id,
			data,
		}: {
			form_id: string;
			data: Record<string, unknown>;
		}) => APIVersion2SubmitFormResponses(form_id, data),
	});
};
