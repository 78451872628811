import { z } from "zod";

interface TimeSlot {
	start_time: string;
	end_time: string;
}

interface Business {
	id: number;
	name: string;
	address: string;
	logo: string | null;
}
interface Location {
	id: number;
	name: string;
	address: string;
	stations: Station[];
	services: Service[];
}

export interface ScheduleStationInfo {
	id: number;
	name: string;
}
export interface Service {
	id: number;
	name: string;
	title: string;
	business_id: number;
	time_in_minute: number;
	appointment_methods: { id: string; name: string }[];
	description: string;
	created_at: string;
	updated_at: string;
}

export interface Station {
	id: number;
	name: string;
	address: string;
	admin_id: number;
	location_id: number;
	business_id: number;
	location: Location;
	business: Business;
}

interface CustomIntake {
	id: number;
	key: string;
	name: string;
	subtitle: string;
	field_requirement: string;
	apply_to: string;
	type: string;
	created_at: string;
	updated_at: string;
	relation_id: number;
	long_text?: number;
	options?: string[];
	allow_multiple?: number;
}

interface ScheduleSetting {
	id: number;
	is_visible: number;
	setting_id: number;
	created_at: string;
	updated_at: string;
	auto_approve: number;
	schedule_block_away_count: number;
	schedule_block_away_option: string;
	join_from_qr: number;
	join_from_url: number;
	is_open: number;
	url_code: string;
	schedule_block_in_minute: number;
	qr_code: string;
	join_url: string;
	qr_code_url: string;
}

export interface ScheduleStationData {
	business: ScheduleBusinessData;
	location: ScheduleLocationData;
	time_slots: TimeSlot[];
	appointment_types: any[];
	scheduling_through: string;
	station: Station;
	stations: Station[];
	services: Service[];
	custom_intakes: CustomIntake[];
	schedule_setting: ScheduleSetting;
	validators: any[];
}

export interface ScheduleLocationData {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	time_zone: string;
	average_waiting_time: number | null;
	use_average_wait_time: number;
	image: string | null;
	phone_number: string | null;
	description: string | null;
	url_code: string;
	qr_code: string;
	stations: Station[];
	services: Service[];
	validators: any[];
}

export interface ScheduleBusinessData {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	logo_url: string;
	admin_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	theme: string;
	business_category_id: number | null;
	schedule_auto_confirm: number;
	is_schedule_notify_open: number;
	average_waiting_time: number | null;
	use_average_wait_time: number;
	zip_code: string;
	locations: Location[];
	logo: string | null;
	validators: any[];
}

export interface ScheduleInfoData
	extends ScheduleBusinessData,
		ScheduleStationData,
		ScheduleLocationData {}

// Define the full response type

export interface ScheduleInfoResponse {
	status: boolean;
	message: string;
	code_type: string;
	verification: {
		action_on: "yes" | "no";
		action_url: string;
		field_title: string;
		is_enabled: 1 | 0;
	};
	validators: any[] | undefined;
	data: ScheduleBusinessData & ScheduleStationData & ScheduleLocationData;
}

export interface ScheduleBusinessResponse {
	status: boolean;
	message: string;
	data: ScheduleBusinessData;
}
export interface ScheduleStationResponse {
	status: boolean;
	message: string;
	data: ScheduleStationData;
}

export const createScheduleCustomFieldsSchema = (
	customIntakes: CustomIntake[]
) => {
	const customIntakeSchema: Record<string, z.ZodTypeAny> = {};

	customIntakes.forEach((intake) => {
		let fieldSchema: z.ZodTypeAny;

		switch (intake.type) {
			case "text":
				fieldSchema = z.string();
				break;
			case "numeric":
				fieldSchema = z.string();
				break;
			case "boolean":
				fieldSchema = z.boolean();
				break;
			case "date":
				fieldSchema = z.date();
				break;
			case "date_range":
				fieldSchema = z.object({
					from: z.date(),
					to: z.date(),
				});
				break;
			case "dropdown":
				fieldSchema = z.array(
					z.object({
						label: z.string(),
						value: z.string(),
					})
				);
				break;
			case "checkbox":
				fieldSchema = z
					.array(z.string())
					.nonempty(`${intake.name} is required`);
				break;

			case "attachment":
				fieldSchema = z.instanceof(File);
				break;
			default:
				fieldSchema = z.any();
		}

		if (intake.field_requirement === "yes") {
			fieldSchema = z.preprocess(
				(val) => {
					if (val === "" || val === null || val === undefined) {
						return undefined;
					}
					return val;
				},
				fieldSchema.refine((val) => val !== undefined, {
					message: `${intake.name} is required`,
				})
			);
		} else {
			fieldSchema = fieldSchema.optional();
		}

		customIntakeSchema[intake.key] = fieldSchema;
	});

	// Return just the custom_intakes schema
	return z.object({
		custom_intakes: z.object(customIntakeSchema).optional(),
	});
};

export interface CustomerValidationResponse {
	status: boolean;
	message: string;
	customer_verification_token: string | null;
	code_type: string;
	data: {
		id: number;
		name: string;
		location: {
			id: number;
			name: string;
			address: string;
			image_url: string | null;
		};
		business: { id: number; name: string; logo: string | null };
		services: { id: number; name: string; time_in_minute: number }[];
		appointment_types: any[];
		time_slots: { start_time: string; end_time: string }[];
	};
	customer: PatientInformationCustomerValidation;
}

export interface PatientInformationCustomerValidation {
	id: number;
	full_name: string;
	email: string;
	phone_number: string;
	business_id: number;
	application_type: string;
	emr_patient_id: string | number | null;
	sync_error: string | number | null;
	created_at: string;
	updated_at: string;
	health_card_expire_date: string | number | null;
	conversation_sid: string | number | null;
	is_follow_up: number;
	external_id: string;
	last_visit: string | null;
	is_active: boolean;
	first_name: string;
	last_name: string;
}

export interface PatientAppointmentInformation {
	id: number;
	customer_id: number;
	assigned_user_id: number | null;
	approved_user_id: number | null;
	cancelled_user_id: number | null;
	track_code: string;
	appointment_date: string;
	approved_at: string | null;
	cancelled_at: string | null;
	service_start_at: string | null;
	service_done_at: string | null;
	cancelled_reason: string | null;
	status: string;
	note: string | null;
	is_active: number;
	emr_id: number | null;
	created_at: string;
	updated_at: string;
	first_appointment_date: string | null;
	rescheduled_at: string | null;
	business_id: number | null;
	location_id: number;
	station_id: number;
	order_at: string;
	selected_slot_in_minute: number;
	appointment_type_id: number | null;
	scheduled_through: string;
	end_at: string;
	check_in_status: string | null;
	check_in_at: string | null;
	priority: string;
	service_id: number | null;
	waitlist_id: number | null;
	is_on_notification_waitlist: number;
	method_id: number;
	track_url: string;
	method: string;
	customer: PatientAppointmentCustomer;
	form_response: any | null;
	service: any | null;
	station: PatientAppointmentStation;
	cancel_warning_message: string | null;
}

interface PatientAppointmentCustomer {
	id: number;
	full_name: string;
	email: string | null;
	phone_number: string;
	business_id: number;
	application_type: string;
	emr_patient_id: string | null;
	sync_error: string | null;
	created_at: string;
	updated_at: string;
	health_card_expire_date: string | null;
	conversation_sid: string | null;
	is_follow_up: number;
	external_id: string;
	last_visit: string | null;
	is_active: number;
	first_name: string;
	last_name: string;
	custom_intakes: PatientAppointmentCustomIntake[];
}

interface PatientAppointmentCustomIntake {
	key: string;
	name: string;
	type: string;
	value?: string;
	id: number;
	subtitle?: string;
	field_requirement?: string;
	apply_to?: string;
	is_visible?: number;
	order?: number | null;
	created_at?: string;
	updated_at?: string;
	long_text?: number;
}

interface PatientAppointmentStation {
	id: number;
	name: string;
	location_id: number;
	business_id: number;
	custom_intakes: PatientAppointmentCustomIntake[];
	address: string;
	location: PatientAppointmentLocation;
	business: PatientAppointmentBusiness;
}

interface PatientAppointmentLocation {
	id: number;
	name: string;
	address: string;
	image_url: string | null;
}

interface PatientAppointmentBusiness {
	id: number;
	name: string;
	logo: string | null;
}
