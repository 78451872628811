import { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
	CardFooter,
} from "@/src/components/ui/card";
import { Label } from "@/src/components/ui/label";
import { Input } from "@/src/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/src/components/ui/radio-group";
import { Checkbox } from "@/src/components/ui/checkbox";
import { Button } from "@/src/components/ui/button";
import { IoInformationCircle } from "react-icons/io5";
import { FormSections } from "../../../types/form-renderer";
import Loader from "../../../components/Loader/Loader";
import useCustomToast from "../../../components/CustomToast";
import { DatePicker } from "@/src/components/ui/datepicker";
import DateRangePicker from "@/src/components/ui-extended/date-range-picker";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import { DateRange } from "react-day-picker";
import SubmissionBlockModal from "./SubmissionBlockModal";
import InfoTextRenderer from "./InfoTextRenderer";

interface FormRenderProps {
	data: any;
	isSubmissionSuccessful: boolean;
	isSubmissionLoading: boolean;
	onSubmit: (formResponses: any) => void;
}

interface Condition {
	type: "goto" | "submit" | "continue" | "block";
	destination?: string;
	conditional_block_message?: string;
}

interface Option {
	id: string;
	value: string;
	conditions?: Condition;
}

const FormRenderer = ({
	data,
	isSubmissionSuccessful,
	isSubmissionLoading,
	onSubmit,
}: FormRenderProps) => {
	const [navigationHistory, setNavigationHistory] = useState([0]);
	const [visitedSections, setVisitedSections] = useState(new Set());
	const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
	const [showBlockSubmissionModal, setShowBlockSubmissionModal] =
		useState(false);
	const [blockAccessMessage, setBlockAccessMessage] = useState(
		data?.block_message || ""
	);
	const [formResponses, setFormResponses] = useState({});
	const toast = useCustomToast();

	const sections: FormSections = data?.sections;
	const formTitle = data?.name;
	const submissionMessage = data?.success_message;
	const submissionButtonTitle = data?.success?.submit_button_title;
	const formBanner = data?.banner_url || data?.logo_url;

	const handleInputChange = (fieldId, value) => {
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: value,
		}));
	};

	const handleOptionSelect = (fieldId, selectedOption) => {
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: selectedOption.value,
		}));
	};

	const handleCheckboxChange = (fieldId, option, checked) => {
		setFormResponses((prev) => {
			const currentValues = prev[fieldId] || [];

			let newValues;
			if (checked) {
				newValues = currentValues.includes(option.value)
					? currentValues
					: [...currentValues, option.value];
			} else {
				newValues = currentValues.filter(
					(value) => value !== option.value
				);
			}

			return {
				...prev,
				[fieldId]: newValues,
			};
		});
	};
	const handleDateChange = (fieldId, date) => {
		const formattedDate = date ? new Date(date).toISOString() : null;
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: formattedDate,
		}));
	};

	const handleDateRangeChange = (
		fieldId: string,
		range: DateRange | undefined
	) => {
		if (!range) return;

		const formattedRange = {
			from: range.from ? new Date(range.from).toISOString() : null,
			to: range.to ? new Date(range.to).toISOString() : null,
		};

		setFormResponses((prev) => ({
			...prev,
			[fieldId]: formattedRange,
		}));
	};

	const handleFileChange = (fieldId: string, files: FileList | null) => {
		if (!files || files.length === 0) return;
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: files[0],
		}));
	};

	const handleDropdownChange = (fieldId, selectedOption) => {
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: selectedOption.value,
		}));
	};

	const handleNumberChange = (fieldId, value) => {
		const numericValue = value === "" ? "" : Number(value);
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: numericValue,
		}));
	};

	const checkForBlockConditions = () => {
		if (!sections || sections.length === 0) return false;

		const currentSection = sections[currentSectionIndex];

		for (const field of currentSection.fields) {
			if (["radio", "dropdown"].includes(field.type)) {
				const selectedValue = formResponses[field.id];

				if (!field.options) continue;

				const selectedOption = field.options.find(
					(opt: Option) => opt.value === selectedValue
				);
				if (
					selectedOption?.conditions &&
					selectedOption.conditions.type === "block"
				) {
					// Set block message from the selected option's condition if available
					if (selectedOption.conditions.conditional_block_message) {
						setBlockAccessMessage(
							selectedOption.conditions.conditional_block_message
						);
					}
					return true;
				}
			}
		}

		if (
			currentSection.flow?.action &&
			currentSection.flow.action === "block"
		) {
			return true;
		}

		return false;
	};

	const processCondition = (condition) => {
		switch (condition.type) {
			case "submit":
				handleSubmit();
				break;
			case "goto": {
				const nextSection = sections.find(
					(section) => section.id === condition.destination
				);
				if (nextSection) {
					const nextIndex = sections.indexOf(nextSection);

					if (visitedSections.has(nextIndex)) {
						toast("Circular navigation detected", {
							type: "error",
							id: "navigation-error",
						});
						return;
					}

					setVisitedSections((prev) => new Set(prev).add(nextIndex));
					setNavigationHistory((prev) => [
						...prev,
						currentSectionIndex,
					]);

					setCurrentSectionIndex(nextIndex);
				}
				if (!nextSection) {
					toast("Invalid section navigation", {
						type: "error",
						id: "form-error",
					});
				}
				break;
			}
			case "continue":
				if (currentSectionIndex < sections.length - 1) {
					setNavigationHistory((prev) => [
						...prev,
						currentSectionIndex,
					]);
					setCurrentSectionIndex(currentSectionIndex + 1);
				}
				break;
			case "block":
				if (condition.conditional_block_message) {
					setBlockAccessMessage(condition.conditional_block_message);
				}
				setShowBlockSubmissionModal(true);
				break;
			default:
				console.warn(`Unhandled condition type: ${condition.type}`);
				break;
		}
	};

	const handleSubmit = async () => {
		if (checkForBlockConditions()) {
			setShowBlockSubmissionModal(true);
			return;
		}

		let isValid = true;

		for (let i = 0; i < sections.length; i++) {
			if (!validateSection(i)) {
				isValid = false;
				if (currentSectionIndex !== i) {
					setCurrentSectionIndex(i);
					break;
				}
			}
		}

		if (!isValid) {
			toast("Please fix validation errors before submitting", {
				type: "error",
				id: "form-submission",
			});
			return;
		}

		if (!isFormComplete()) {
			toast("Please complete all required fields", {
				type: "error",
				id: "form-submission",
			});
			return;
		}

		try {
			await onSubmit(formResponses);
		} catch (error) {
			toast("Form Submission Error", {
				id: "form-submission",
				type: "error",
			});
		}
	};

	//todo: this should work properly after update to infoimage and infotext
	const isFormComplete = () => {
		if (sections) {
			for (const section of sections) {
				for (const field of section.fields) {
					if (field.required && !formResponses[field.id]) {
						return false;
					}
				}
			}
		}

		return true;
	};

	const getDisabledMatchers = (field) => {
		if (!field.dateValidation) return undefined;

		const matchers: any[] = [];

		// Static validation
		if (field.dateValidation.type === "static") {
			if (field.dateValidation.minDate) {
				matchers.push({
					before: new Date(field.dateValidation.minDate),
				});
			}
			if (field.dateValidation.maxDate) {
				matchers.push({
					after: new Date(field.dateValidation.maxDate),
				});
			}
		}

		// Relative validation
		if (field.dateValidation.type === "relative") {
			if (field.dateValidation.minDateOffset) {
				const limitDate = calculateRelativeDate(
					field.dateValidation.minDateOffset
				);
				if (
					limitDate &&
					field.dateValidation.minDateOffset.direction === "past"
				) {
					matchers.push({ before: limitDate });
				} else if (
					limitDate &&
					field.dateValidation.minDateOffset.direction === "future"
				) {
					matchers.push({ after: limitDate });
				}
			}

			if (field.dateValidation.maxDateOffset) {
				const limitDate = calculateRelativeDate(
					field.dateValidation.maxDateOffset
				);
				if (
					limitDate &&
					field.dateValidation.maxDateOffset.direction === "past"
				) {
					matchers.push({ before: limitDate });
				} else if (
					limitDate &&
					field.dateValidation.maxDateOffset.direction === "future"
				) {
					matchers.push({ after: limitDate });
				}
			}
		}

		// Dynamic validation
		if (field.dateValidation.type === "dynamic") {
			if (field.dateValidation.minDateField) {
				const minDateFieldValue =
					formResponses[field.dateValidation.minDateField];
				if (minDateFieldValue) {
					// Handle different formats (date vs date range)
					let minDate;
					if (
						typeof minDateFieldValue === "object" &&
						minDateFieldValue.from
					) {
						minDate = new Date(minDateFieldValue.from);
					} else if (minDateFieldValue) {
						minDate = new Date(minDateFieldValue);
					}

					if (minDate) {
						// Before the min date is disabled
						matchers.push({ before: minDate });
					}
				}
			}

			if (field.dateValidation.maxDateField) {
				const maxDateFieldValue =
					formResponses[field.dateValidation.maxDateField];
				if (maxDateFieldValue) {
					// Handle different formats
					let maxDate;
					if (
						typeof maxDateFieldValue === "object" &&
						maxDateFieldValue.to
					) {
						maxDate = new Date(maxDateFieldValue.to);
					} else if (maxDateFieldValue) {
						maxDate = new Date(maxDateFieldValue);
					}

					if (maxDate) {
						// After the max date is disabled
						matchers.push({ after: maxDate });
					}
				}
			}
		}

		return matchers.length > 0 ? matchers : undefined;
	};
	const canSelectDate = (field) => {
		if (!field.dateValidation || field.dateValidation.type !== "dynamic") {
			return true;
		}

		if (
			field.dateValidation.minDateField &&
			!formResponses[field.dateValidation.minDateField]
		) {
			return false;
		}

		if (
			field.dateValidation.maxDateField &&
			!formResponses[field.dateValidation.maxDateField]
		) {
			return false;
		}

		return true;
	};

	const canSelectDateRange = (field) => {
		if (!field.dateValidation || field.dateValidation.type !== "dynamic") {
			return true;
		}

		if (
			field.dateValidation.minDateField &&
			!formResponses[field.dateValidation.minDateField]
		) {
			return false;
		}

		if (
			field.dateValidation.maxDateField &&
			!formResponses[field.dateValidation.maxDateField]
		) {
			return false;
		}

		return true;
	};

	const calculateRelativeDate = (offsetConfig) => {
		if (!offsetConfig) return null;

		const { value, unit, direction } = offsetConfig;
		const today = new Date();
		const limitDate = new Date();

		const multiplier = direction === "past" ? -1 : 1;

		switch (unit) {
			case "days":
				limitDate.setDate(today.getDate() + value * multiplier);
				break;
			case "months":
				limitDate.setMonth(today.getMonth() + value * multiplier);
				break;
			case "years":
				limitDate.setFullYear(today.getFullYear() + value * multiplier);
				break;
		}

		return limitDate;
	};

	useEffect(() => {
		if (isSubmissionSuccessful) {
			setCurrentSectionIndex(sections.length);
			setVisitedSections(new Set());
		}
	}, [isSubmissionSuccessful, sections?.length]);

	const validationRules = {
		longtext: (value, field) => {
			if (field.required && !value) return "This is a required question";
			if (field.minLength && value.length < field.minLength)
				return `Minimum ${field.minLength} characters required`;
			return null;
		},
		text: (value, field) => {
			if (field.required && !value) return "This is a required question";
			if (field.minLength && value.length < field.minLength)
				return `Minimum ${field.minLength} characters required`;
			return null;
		},
		radio: (value, field) => {
			if (field.required && !value) return "Please select an option";
			const validOptions = field.options.map((opt: any) => opt.value);
			if (value && !validOptions.includes(value))
				return "Please select a valid option";
			return null;
		},
		checkbox: (value, field) => {
			if ((field.required && !value) || value.length === 0)
				return "Please select an option";
			return null;
		},
		numeric: (value: number | string, field: any) => {
			if (field.required && (value === "" || value === undefined))
				return "This field is required";
			if (value === "") return null;
			const numValue = Number(value);
			if (field.required && isNaN(numValue))
				return "Please enter a valid number";
			if (field.required && field.min && numValue < field.min)
				return `Minimum value is ${field.min}`;
			if (field.required && field.max && numValue > field.max)
				return `Maximum value is ${field.max}`;
			return null;
		},
		date: (value, field) => {
			if (field.required && !value) return "This field is required";
			if (value && !Date.parse(value)) return "Please enter a valid date";

			if (field.dateValidation && value) {
				const selectedDate = new Date(value);

				if (field.dateValidation.type === "static") {
					if (
						field.dateValidation.minDate &&
						new Date(field.dateValidation.minDate) > selectedDate
					) {
						return `Date must be on or after ${new Date(field.dateValidation.minDate).toLocaleDateString()}`;
					}
					if (
						field.dateValidation.maxDate &&
						new Date(field.dateValidation.maxDate) < selectedDate
					) {
						return `Date must be on or before ${new Date(field.dateValidation.maxDate).toLocaleDateString()}`;
					}
				}

				if (field.dateValidation.type === "relative") {
					if (field.dateValidation.minDateOffset) {
						const limitDate = calculateRelativeDate(
							field.dateValidation.minDateOffset
						);

						if (
							limitDate &&
							field.dateValidation.minDateOffset.direction ===
								"past"
						) {
							// If direction is "past", selected date should be AFTER the limit date (which is in the past)
							if (selectedDate < limitDate) {
								const { value, unit } =
									field.dateValidation.minDateOffset;
								return `Date must be within the last ${value} ${unit}`;
							}
						} else if (
							limitDate &&
							field.dateValidation.minDateOffset.direction ===
								"future"
						) {
							// If direction is "future", selected date should be BEFORE the limit date (which is in the future)
							if (selectedDate > limitDate) {
								const { value, unit } =
									field.dateValidation.minDateOffset;
								return `Date must be within the next ${value} ${unit}`;
							}
						}
					}

					if (field.dateValidation.maxDateOffset) {
						const limitDate = calculateRelativeDate(
							field.dateValidation.maxDateOffset
						);

						if (
							limitDate &&
							field.dateValidation.maxDateOffset.direction ===
								"past"
						) {
							if (selectedDate < limitDate) {
								return `Date cannot be earlier than ${limitDate.toLocaleDateString()}`;
							}
						} else if (
							limitDate &&
							field.dateValidation.maxDateOffset.direction ===
								"future"
						) {
							if (selectedDate > limitDate) {
								return `Date cannot be later than ${limitDate.toLocaleDateString()}`;
							}
						}
					}
				}

				if (field.dateValidation.type === "dynamic") {
					if (field.dateValidation.minDateField) {
						const minDateFieldValue =
							formResponses[field.dateValidation.minDateField];
						if (
							minDateFieldValue &&
							new Date(minDateFieldValue) > selectedDate
						) {
							return "Date must be on or after the selected minimum date";
						}
					}

					if (field.dateValidation.maxDateField) {
						const maxDateFieldValue =
							formResponses[field.dateValidation.maxDateField];

						// For date_range fields, need to check if it's an object with 'from' or 'to'
						let maxDate;
						if (
							maxDateFieldValue &&
							typeof maxDateFieldValue === "object"
						) {
							// Use 'to' date for max comparison if available, otherwise fall back to 'from'
							if (maxDateFieldValue.to) {
								maxDate = new Date(maxDateFieldValue.to);
							} else if (maxDateFieldValue.from) {
								maxDate = new Date(maxDateFieldValue.from);
							}
						} else if (maxDateFieldValue) {
							maxDate = new Date(maxDateFieldValue);
						}

						if (maxDate && maxDate < selectedDate) {
							return "Date must be on or before the selected maximum date";
						}
					}
				}
			}

			return null;
		},

		date_range: (value, field) => {
			if (field.required && (!value || !value.from || !value.to)) {
				return "Both dates are required";
			}

			if (value && value.from && value.to) {
				const fromDate = new Date(value.from);
				const toDate = new Date(value.to);

				if (fromDate > toDate) {
					return "Start date must be before end date";
				}

				// Handle date validation
				if (field.dateValidation) {
					// Static validation
					if (field.dateValidation.type === "static") {
						if (
							field.dateValidation.minDate &&
							new Date(field.dateValidation.minDate) > fromDate
						) {
							return `Start date must be on or after ${new Date(field.dateValidation.minDate).toLocaleDateString()}`;
						}
						if (
							field.dateValidation.maxDate &&
							new Date(field.dateValidation.maxDate) < toDate
						) {
							return `End date must be on or before ${new Date(field.dateValidation.maxDate).toLocaleDateString()}`;
						}
					}

					// Relative validation
					if (field.dateValidation.type === "relative") {
						// const today = new Date();

						if (field.dateValidation.minDateOffset) {
							const limitDate = calculateRelativeDate(
								field.dateValidation.minDateOffset
							);

							if (
								limitDate &&
								field.dateValidation.minDateOffset.direction ===
									"past"
							) {
								// For past direction, fromDate should be AFTER the limit date
								if (fromDate < limitDate) {
									const { value, unit } =
										field.dateValidation.minDateOffset;
									return `Range must be within the last ${value} ${unit}`;
								}
							} else if (
								limitDate &&
								field.dateValidation.minDateOffset.direction ===
									"future"
							) {
								// For future direction, fromDate should be BEFORE the limit date
								if (fromDate > limitDate) {
									const { value, unit } =
										field.dateValidation.minDateOffset;
									return `Range must be within the next ${value} ${unit}`;
								}
							}
						}

						if (field.dateValidation.maxDateOffset) {
							const limitDate = calculateRelativeDate(
								field.dateValidation.maxDateOffset
							);

							if (
								limitDate &&
								field.dateValidation.maxDateOffset.direction ===
									"past"
							) {
								if (fromDate < limitDate) {
									return `Range cannot be earlier than ${limitDate.toLocaleDateString()}`;
								}
							} else if (
								limitDate &&
								field.dateValidation.maxDateOffset.direction ===
									"future"
							) {
								if (toDate > limitDate) {
									return `Range cannot be later than ${limitDate.toLocaleDateString()}`;
								}
							}
						}
					}

					// Dynamic validation based on other field values
					if (field.dateValidation.type === "dynamic") {
						if (field.dateValidation.minDateField) {
							const minDateFieldValue =
								formResponses[
									field.dateValidation.minDateField
								];
							if (minDateFieldValue) {
								let minDate;
								if (
									typeof minDateFieldValue === "object" &&
									minDateFieldValue.from
								) {
									minDate = new Date(minDateFieldValue.from);
								} else {
									minDate = new Date(minDateFieldValue);
								}

								if (minDate > fromDate) {
									return "Start date must be on or after the selected minimum date";
								}
							}
						}

						if (field.dateValidation.maxDateField) {
							const maxDateFieldValue =
								formResponses[
									field.dateValidation.maxDateField
								];

							let maxDate;
							if (
								maxDateFieldValue &&
								typeof maxDateFieldValue === "object"
							) {
								if (maxDateFieldValue.to) {
									maxDate = new Date(maxDateFieldValue.to);
								} else if (maxDateFieldValue.from) {
									maxDate = new Date(maxDateFieldValue.from);
								}
							} else if (maxDateFieldValue) {
								maxDate = new Date(maxDateFieldValue);
							}

							if (maxDate && maxDate < toDate) {
								return "End date must be on or before the selected maximum date";
							}
						}
					}
				}
			}

			return null;
		},
		dropdown: (value: string, field: any) => {
			if (field.required && !value) return "Please select an option";
			const validOptions = field.options.map((opt: any) => opt.value);
			if (value && !validOptions.includes(value))
				return "Please select a valid option";
			return null;
		},
		attachment: (value: File | null, field: any) => {
			if (field.required && !value) return "Please upload a file";
			if (value) {
				const extension = value.name.split(".").pop()?.toLowerCase();
				const allowedFormats = field.approved_formats.map(
					(format: string) => format.toLowerCase()
				);
				if (!extension || !allowedFormats.includes(extension)) {
					return `Please upload a file in one of these formats: ${field.approved_formats.join(", ")}`;
				}
				if (field.maxSize && value.size > field.maxSize) {
					return `File size must be less than ${field.maxSize / (1024 * 1024)}MB`;
				}
			}
			return null;
		},
	};

	const [errors, setErrors] = useState({});

	const validateSection = (sectionIndex: number) => {
		const newErrors: { [key: string]: string } = {};
		const section = sections[sectionIndex];

		section.fields.forEach((field) => {
			const validator =
				validationRules[field.type as keyof typeof validationRules];
			if (validator) {
				const error = validator(formResponses[field.id], field);
				if (error) newErrors[field.id] = error;
			}
		});

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleNext = () => {
		if (!validateSection(currentSectionIndex)) return;
		const currentSection = sections[currentSectionIndex];

		for (const field of currentSection.fields) {
			if (["radio", "dropdown"].includes(field.type)) {
				const selectedValue = formResponses[field.id];
				const selectedOption =
					field.options &&
					field.options.find((opt) => opt.value === selectedValue);

				if (selectedOption?.conditions) {
					processCondition(selectedOption.conditions);
					return;
				}
			}
		}

		//todo: Default next behavior and also try to consider other conditions
		if (currentSection.flow?.targetSection) {
			const targetIndex = sections.findIndex(
				(s) => s.id === currentSection.flow.targetSection
			);
			if (targetIndex !== -1) {
				setNavigationHistory((prev) => [...prev, currentSectionIndex]);
				setCurrentSectionIndex(targetIndex);
				return;
			}
		}

		if (currentSectionIndex < sections.length - 1) {
			setNavigationHistory((prev) => [...prev, currentSectionIndex]);
			setCurrentSectionIndex(currentSectionIndex + 1);
		} else {
			handleSubmit();
		}
	};

	const handleBack = () => {
		if (navigationHistory.length > 1) {
			const prevHistory = [...navigationHistory];
			const prevIndex = prevHistory.pop() || 0;
			setNavigationHistory(prevHistory);
			setCurrentSectionIndex(prevIndex);
		}
	};

	const renderField = (field) => {
		switch (field.type) {
			//todo: update later to textarea
			case "longtext":
			case "text":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label
							className="text-base font-bold"
							htmlFor={field.id}
						>
							{field.title}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						{/* //todo: add description to it */}
						<Input
							id={field.id}
							value={formResponses[field.id] || ""}
							onChange={(e) =>
								handleInputChange(field.id, e.target.value)
							}
							placeholder="Your Answer"
							required={field.required}
							className="w-full"
						/>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);
			case "radio":
				return (
					<div
						key={field.id}
						className="flex flex-col justify-start gap-y-3"
					>
						<Label className="text-base font-bold">
							{field.title}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<RadioGroup
							onValueChange={(value) => {
								const selectedOption = field.options.find(
									(opt) => opt.id === value
								);
								if (selectedOption) {
									handleOptionSelect(
										field.id,
										selectedOption
									);
								}
							}}
							className="flex flex-col gap-y-2"
							value={
								field.options.find(
									(opt) =>
										opt.value === formResponses[field.id]
								)?.id
							}
						>
							{field.options.map((option) => (
								<div
									key={option.id}
									className="flex items-center gap-x-2"
								>
									<RadioGroupItem
										value={option.id}
										id={option.id}
									/>
									<Label htmlFor={option.id}>
										{option.value}
									</Label>
								</div>
							))}
						</RadioGroup>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);
			case "numeric":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label
							className="text-base font-bold"
							htmlFor={field.id}
						>
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<Input
							id={field.id}
							type="number"
							placeholder="Your Answer"
							value={formResponses[field.id] || ""}
							onChange={(e) =>
								handleNumberChange(field.id, e.target.value)
							}
							required={field.required}
							className="w-full"
						/>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "date":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<DatePicker
							date={
								formResponses[field.id]
									? new Date(formResponses[field.id])
									: undefined
							}
							setDate={(date) => handleDateChange(field.id, date)}
							disabled={getDisabledMatchers(field)}
						/>

						{field.dateValidation?.type === "static" && (
							<div className="mt-1 text-sm text-gray-500">
								{field.dateValidation.minDate &&
									`Must be on or after ${new Date(field.dateValidation.minDate).toLocaleDateString()}.`}{" "}
								{field.dateValidation.maxDate &&
									`Must be on or before ${new Date(field.dateValidation.maxDate).toLocaleDateString()}.`}
							</div>
						)}

						{field.dateValidation?.type === "dynamic" &&
							!canSelectDate(field) && (
								<div className="mt-1 text-sm text-amber-600">
									Please fill in the referenced date field(s)
									first
								</div>
							)}

						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "date_range":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<DateRangePicker
							value={{
								from: formResponses[field.id]?.from
									? new Date(formResponses[field.id].from)
									: undefined,
								to: formResponses[field.id]?.to
									? new Date(formResponses[field.id].to)
									: undefined,
							}}
							onChange={(range) =>
								handleDateRangeChange(field.id, range)
							}
							disabled={getDisabledMatchers(field)}
						/>

						{field.dateValidation?.type === "static" && (
							<div className="mt-1 text-sm text-gray-500">
								{field.dateValidation.minDate &&
									`Start date must be on or after ${new Date(field.dateValidation.minDate).toLocaleDateString()}.`}{" "}
								{field.dateValidation.maxDate &&
									`End date must be on or before ${new Date(field.dateValidation.maxDate).toLocaleDateString()}.`}
							</div>
						)}

						{field.dateValidation?.type === "dynamic" &&
							!canSelectDateRange(field) && (
								<div className="mt-1 text-sm text-amber-600">
									Please fill in the referenced date field(s)
									first
								</div>
							)}

						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "dropdown":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<Select
							value={formResponses[field.id]}
							onValueChange={(value) => {
								const selectedOption = field.options.find(
									(opt) => opt.value === value
								);
								handleDropdownChange(field.id, selectedOption);
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select an option" />
							</SelectTrigger>
							<SelectContent>
								{field.options.map((option) => (
									<SelectItem
										key={option.id}
										value={option.value}
									>
										{option.value}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "checkbox":
				return (
					<div key={field.id} className="flex flex-col gap-y-3">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<div className="flex flex-col gap-y-2">
							{field.options.map((option) => (
								<div
									key={option.id}
									className="flex items-center gap-x-2"
								>
									<Checkbox
										id={option.id}
										checked={(
											formResponses[field.id] || []
										).includes(option.value)}
										onCheckedChange={(checked) =>
											handleCheckboxChange(
												field.id,
												option,
												checked
											)
										}
									/>
									<Label htmlFor={option.id}>
										{option.value}
									</Label>
								</div>
							))}
						</div>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);
			case "attachment":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}
							{field.required && (
								<span className="pl-[2px] text-destructive">
									*
								</span>
							)}
						</Label>
						<div key={field.id} className="">
							<Input
								type="file"
								accept={field.approved_formats
									.map((format) => `.${format.toLowerCase()}`)
									.join(",")}
								onChange={(e) =>
									handleFileChange(field.id, e.target.files)
								}
								required={field.required}
							/>
						</div>
						{field.description && (
							<p className="text-sm text-gray-500">
								Accepted formats:{" "}
								{field.approved_formats.join(", ")}
							</p>
						)}
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-destructive">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "infoText":
				return (
					<div className="text-base font-medium text-gray-900">
						<InfoTextRenderer
							value={field?.info_text_value}
							className="my-custom-class"
						/>
					</div>
				);
			default:
				console.warn(`Field type not handled yet, ${field.type}`);
				return <p>Unsupported field type: {field.type}</p>;
		}
	};

	if (!sections || sections.length === 0) {
		return (
			<div className="flex min-h-screen items-center justify-center">
				<p>No form sections available.</p>
			</div>
		);
	}

	if (currentSectionIndex >= sections?.length) {
		return (
			<>
				<div className="flex min-h-screen flex-col justify-center bg-[#E5EEF4] p-4">
					<div className="mx-auto my-auto w-full max-w-sm">
						<Card className="flex h-[30vh] w-full flex-col items-center justify-center border-t-8 border-t-primary">
							<CardHeader className="text-center">
								<CardTitle className="text-base">
									{submissionMessage ||
										"Thank you for your responses!"}
								</CardTitle>
							</CardHeader>
						</Card>
					</div>
				</div>
			</>
		);
	}

	const currentSection = sections && sections[currentSectionIndex];
	return (
		<>
			{/* <div className="flex min-h-screen flex-col justify-center bg-[#E5EEF4] p-4"> */}
			<div className="mx-auto my-auto w-full max-w-lg ">
				{formBanner && (
					<div className="mb-2 flex h-[150px] w-full flex-col items-center justify-center  rounded-lg bg-white px-6 py-1 md:w-full md:max-w-[80%] lg:max-w-full">
						<img
							src={formBanner}
							className="pointer-events-none h-full w-full object-contain"
							alt="bg-image"
						/>
					</div>
				)}

				<Card className="flex min-h-[80vh] w-full flex-col border-t-8 border-t-primary">
					<div className="mx-6 border-b-2 pb-2 pt-4">
						<h2 className="text-3xl font-bold">
							{formTitle || "Service Form"}
						</h2>
						{/* <p className="text-[#64748B]">
								Take up new information from the patient
							</p> */}
					</div>
					<CardHeader>
						<CardTitle>{currentSection?.title}</CardTitle>
						{currentSection?.description && (
							<CardDescription className="text-[#64748B]">
								{currentSection?.description}
							</CardDescription>
						)}
					</CardHeader>
					<CardContent className="flex flex-col gap-y-6">
						{currentSection?.fields?.map(renderField)}
					</CardContent>
					<CardFooter className="mt-auto flex justify-between gap-x-2">
						<Button
							onClick={handleBack}
							variant="outline"
							disabled={currentSectionIndex === 0}
						>
							Back
						</Button>
						<div className=" h-2 w-full max-w-48 rounded-full bg-gray-200">
							<div
								className="h-2 rounded-full bg-primary transition-all"
								style={{
									width: `${sections?.length ? ((currentSectionIndex + 1) / sections.length) * 100 : 0}%`,
								}}
							/>
						</div>
						<div className="flex items-center gap-x-2">
							<Button
								disabled={isSubmissionLoading}
								onClick={handleSubmit}
								variant="outline"
								className="h-10 w-16"
							>
								{isSubmissionLoading ? (
									<span>
										<Loader size={18} />
									</span>
								) : (
									submissionButtonTitle || "Submit"
								)}
							</Button>
							{currentSectionIndex < sections?.length - 1 && (
								<Button
									disabled={isSubmissionLoading}
									onClick={handleNext}
								>
									Next
								</Button>
							)}
						</div>
					</CardFooter>
				</Card>
			</div>

			{showBlockSubmissionModal && (
				<SubmissionBlockModal
					blockMessage={blockAccessMessage}
					isOpen={showBlockSubmissionModal}
					setIsOpen={setShowBlockSubmissionModal}
				/>
			)}
		</>
	);
};

export default FormRenderer;
