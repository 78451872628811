import { ReactNode } from "react";
import { z } from "zod";
import { StatusOfPatientType } from "../utils/constants";
import { CustomIntake } from "./custom-intakes";
import { ObjectValues } from ".";

export const JoinWaitlistSchema = z.object({
	full_name: z.string().min(1, "Full name is required"),
	phone_number: z
		.string()
		.min(1, "Phone number is required")
		.max(10, "Phone number must be less than 11 characters"),
	email: z.string().email().optional().or(z.literal("").optional()),
	custom_intakes: z.record(z.unknown()).optional(), // custom_intakes can be an object with unknown types
});

export const swapDirectionSchema = z.enum(["earlier", "later"]);
export type SwapDirectionType = z.infer<typeof swapDirectionSchema>;

export const PatientPriority = {
	High: "high",
	Medium: "medium",
	Normal: "normal",
} as const;

export const UpdateWaitlistSchema = z
	.object({
		action: z.enum([
			"cancel",
			"done",
			"check_in",
			"undo_checkin",
			"request_swap",
			"rate",
		]),
		swap_direction: swapDirectionSchema.optional(),
		swap_reason: z.string().optional(),
		rating: z.number().min(1).max(5).optional(),
		comment: z.string().optional(),
	})
	.superRefine((data, ctx) => {
		// Validation for 'request_swap'
		// if (data.action === "request_swap") {
		// 	if (!data.swap_direction) {
		// 		ctx.addIssue({
		// 			code: z.ZodIssueCode.custom,
		// 			message: "Invalid swap position",
		// 			path: ["swap_position"], // path indicates which field is causing the issue
		// 		});
		// 	}
		// 	if (!data.swap_reason) {
		// 		ctx.addIssue({
		// 			code: z.ZodIssueCode.custom,
		// 			message: "Invalid swap reason",
		// 			path: ["swap_reason"],
		// 		});
		// 	}
		// }

		// Validation for 'rate'
		if (data.action === "rate") {
			if (!data.rating) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Invalid rating",
					path: ["rating"],
				});
			}
		}
	});

export interface IWaitListLayoutProps {
	children: ReactNode;
	imageUrl: any | string;
	hasFooterText?: boolean;
	imageStyle?: string;
	hasFooterButton?: boolean;
	href?: string;
	handleClick?: () => void;
	name?: string | undefined;
	isButtonDisabled?: boolean;
	isSubmitting?: boolean;
	loaderSize?: number;
}

export interface IWaitListSubTextProps {
	imageUrl: string;
	content: string;
	className?: string;
}

export type Option = {
	label: string;
	value: string;
	disabled?: boolean;
	metadata?: any;
};

interface JoinTimeSlot {
	id: number;
	start_time: string; // ISO time string "HH:MM:SS"
	end_time: string; // ISO time string "HH:MM:SS"
	is_active: number;
	product_working_hour_id: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
}

export interface JoinWorkingHour {
	id: number;
	day_of_week: string; // e.g., "monday", "tuesday"
	workable_id: number;
	workable_type: string; // e.g., "App\\Models\\QueueSetting"
	is_active: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	time_slots: JoinTimeSlot[];
}

export interface JoinWorkingHoursErrorResponse {
	error: string;
	message: string;
	working_hours: JoinWorkingHour[];
}

export interface JoinWaitlistSingleStation {
	status: boolean;
	message: string;
	data: {
		waitlist_count: number;
		estimated_wait_time: number;
		joined_through: string;
		station: JoinWaitlistSingleStationStationInfo;
		custom_intakes: CustomIntake[];
		waitlist_setting: JoinWaitlistSingleStationWaitlistSetting;
	};
}

export interface JoinWaitlistSingleStationStationInfo {
	id: number;
	name: string;
	address: string;
	admin_id: number;
	location_id: number;
	business_id: number;
	location: JoinWaitlistSingleStationLocation;
	business: JoinWaitlistBusiness;
}

export interface JoinWaitlistSingleStationLocation {
	id: number;
	name: string;
	address: string;
}

export interface JoinWaitlistBusiness {
	id: number;
	name: string;
	address: string;
	theme: string;
}

export interface JoinWaitlistSingleStationWaitlistSetting {
	id: number;
	auto_clearing: number;
	auto_clearing_minute: number;
	auto_flow: number;
	manual_wait_time: number;
	use_average_time: number;
	is_queue_active: number;
	is_visible: number;
	setting_id: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	join_from_qr: number;
	join_from_url: number;
	is_open: number;
	url_code: string;
	qr_code: string;
	join_url: string;
	qr_code_url: string;
	customer_waiting_message: string;
	show_customer_counter: boolean;
	show_customer_waiting_message: boolean;
	show_customer_waiting_time: boolean;
	setting: JoinWaitlistSingleStationSettingDetails;
}

export interface JoinWaitlistSingleStationSettingDetails {
	id: number;
	auto_approve: number;
	is_visible: number;
	settingable_id: number;
	settingable_type: string;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	settingable: JoinWaitlistSingleStationSettingableDetails;
}

export interface JoinWaitlistSingleStationSettingableDetails {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	is_active: number;
	business_id: number;
	schedule_before: null | string;
	average_waiting_time: null | string;
	use_average_wait_time: number;
	custom_fields: CustomIntake[];
	address: string;
	location: Location;
	business: JoinWaitlistBusiness;
	queue_setting: JoinWaitlistSingleStationQueueSetting;
	average_data: JoinWaitlistSingleStationAverageData;
}

export interface JoinWaitlistSingleStationQueueSetting {
	id: number;
	auto_approve: number;
	is_visible: number;
	settingable_id: number;
	settingable_type: string;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	working_hours: JoinWorkingHour[];
}

export interface JoinWaitlistSingleStationAverageData {
	station_id: number;
	average_wait_time_in_minute: string;
	manual_wait_time: number;
	use_average_time: number;
	is_queue_active: number;
}

export interface JoinWaitlistLocationResponse {
	status: boolean;
	message: string;
	data: JoinWaitlistLocationData;
}

export interface JoinWaitlistLocationData {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	is_active: number;
	time_zone: string;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	image: string | null;
	phone_number: string | null;
	description: string | null;
	url_code: string;
	qr_code: string;
	stations: JoinWaitlistLocationStationInfo[];
	business?: JoinWaitlistBusiness;
}

export interface JoinWaitlistLocationStationInfo {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	is_active: number;
	business_id: number;
	schedule_before: string | null;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	waitlist_setting: JoinWaitlistLocationWaitlistSetting;
	queue_setting: JoinWaitlistLocationQueueSetting;
}

export interface JoinWaitlistLocationWaitlistSetting {
	id: number;
	auto_clearing: number;
	auto_clearing_minute: number;
	auto_flow: number;
	manual_wait_time: number;
	use_average_time: number;
	is_queue_active: number;
	is_visible: number;
	setting_id: number;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	join_from_qr: number;
	join_from_url: number;
	is_open: number;
	url_code: string;
	qr_code: string;
	join_url: string;
	qr_code_url: string;
	customer_waiting_message: string;
	show_customer_counter: boolean;
	show_customer_waiting_message: boolean;
	show_customer_waiting_time: boolean;
}

export interface JoinWaitlistLocationQueueSetting {
	id: number;
	auto_approve: number;
	is_visible: number;
	settingable_id: number;
	settingable_type: string;
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
	waitlist_setting: JoinWaitlistLocationWaitlistSetting;
}

// export type WaitlistCodeInfoResponse = {
// 	total_customers: number;
// 	position: number;
// 	estimated_wait_time: string;
// 	waitlist_info: {
// 		id: number;
// 		waitlist_id: number;
// 		is_paused: number;
// 		status: StatusOfPatientType;
// 		priority: string;
// 		check_in_status: string | null;
// 		check_in_at: string | null;
// 		track_code: string;
// 		customer_id: number;
// 		station_id: number;
// 		station_name: string;
// 		location_id: number;
// 		location_name: string;
// 		business_id: number;
// 		business_name: string;
// 		joined_at: string;
// 		order_at: string;
// 		swap_request_id: number | null;
// 		address: string;
// 		station_average_in_minute: string;
// 		estimated_wait_time: string;
// 		track_url: string;
// 		customer: {
// 			id: number;
// 			full_name: string;
// 			email: string;
// 			phone_number: string;
// 			business_id: number;
// 			application_type: string;
// 			created_at: string;
// 			updated_at: string;
// 			health_card_expire_date: string | null;
// 			waitlist_id: number;
// 			is_current: boolean;
// 			since: string;
// 			custom_intakes: CustomIntake[]; // assuming custom_intakes can have any structure
// 		};
// 		swap_request: any | null; // assuming swap_request can be of any structure or null
// 	};
// 	queue_order: {
// 		id: number;
// 		full_name: string;
// 		since: string;
// 		waitlist_id: number;
// 		is_current: boolean;
// 	}[];
// };
export type JoinedThroughType = "qr" | "url" | "admin";

export type WaitlistCodeInfoResponse = {
	total_customers: number;
	position: number;
	estimated_wait_time: string;
	waitlist_info: {
		id: number;
		waitlist_id: number;
		is_paused: boolean;
		status: StatusOfPatientType;
		priority: RequestPriority;
		check_in_status: string;
		check_in_at: string;
		track_code: string;
		customer_id: number;
		station_id: number;
		station_name: string;
		location_id: number;
		location_name: string;
		business_id: number;
		business_name: string;
		joined_at: string;
		joined_through: JoinedThroughType;
		order_at: string;
		swap_request_id: number;
		address: string;
		station_average_in_minute: string;
		estimated_wait_time: string;
		customer: {
			id: number;
			full_name: string;
			email: string | null;
			phone_number: string;
			business_id: number;
			application_type: string;
			emr_patient_id: number | null;
			sync_error: string | null;
			created_at: string;
			updated_at: string;
			health_card_expire_date: string | null;
			conversation_sid: string | null;
			is_follow_up: boolean;
			external_id: string | null;
			last_visit: string | null;
			is_active: boolean;
			first_name: string;
			last_name: string;
			waitlist_id: number;
			is_current: boolean;
			since: string;
		};
		swap_request: {
			id: number;
			waitlist_id: number;
			status: "waiting";
			created_at: string;
			updated_at: string;
			reason: string;
			position: null;
			direction: SwapDirectionType;
		} | null;
	};
	waitlist_setting: JoinWaitlistLocationWaitlistSetting;
};

export type JoinWaitlistType = z.infer<typeof JoinWaitlistSchema>;
export type UpdateWaitlistActionType = z.infer<typeof UpdateWaitlistSchema>;
export type PatientPriorityType = ObjectValues<typeof PatientPriority>;
