import { ScheduleFormProvider } from "./helpers/ScheduleFormContext";
import BookTime from "./BookTime";
import AppointmentConfirmation from "./AppointmentConfirmation";
import useScheduleStore from "@/src/store/useScheduleStore";
import ValidationForm from "./ValidationForm";
import BookProviderTime from "./BookProviderTime";
import DetailsForm from "./DetailsForm";
import FillForm from "./FillForm";
import ServiceProviderSelection from "./ServiceProviderSelection";
import BusinessLocationSelection from "./BusinessLocationSelection";

const ScheduleAppointment = () => {
	const { step, bookingType, appointmentChoice, validationDetails } =
		useScheduleStore();

	return (
		<>
			<ScheduleFormProvider>
				{step == 0 &&
					(validationDetails?.length &&
					validationDetails !== undefined ? (
						<ValidationForm />
					) : (
						<DetailsForm />
					))}
				{step === 1 &&
					(bookingType === "business" ? (
						<BusinessLocationSelection />
					) : (
						<ServiceProviderSelection />
					))}
				{step === 1.5 && <ServiceProviderSelection />}
				{step === 2 &&
					(bookingType === "station" ||
					appointmentChoice === "provider" ? (
						<BookProviderTime />
					) : (
						<BookTime />
					))}

				{step == 3 && <FillForm />}
				{step == 4 && <AppointmentConfirmation />}
			</ScheduleFormProvider>
		</>
	);
};

export default ScheduleAppointment;
