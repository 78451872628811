import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import {
	format,
	isValid,
	getMonth,
	getYear,
	setMonth,
	setYear,
} from "date-fns";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./select";
import {
	// DateRange,
	DayPicker,
	Matcher,
	// SelectSingleEventHandler,
} from "react-day-picker";
import { cn } from "@/src/utils/general";

export type DatePickerExistingProps = React.ComponentProps<typeof DayPicker>;

interface DatePickerProps {
	title?: string;
	date?: Date;
	setDate?: (date: Date | undefined) => void;
	className?: string;
	toDate?: Date;
	mode?: "single" | "range";
	disabled?: Matcher | Matcher[];
	startYear?: number;
	endYear?: number;
}

type CombinedDatePickerProps = DatePickerProps & DatePickerExistingProps;

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export function DatePicker({
	className,
	title,
	date: externalDate,
	setDate: externalSetDate,
	toDate,
	disabled,
	startYear = getYear(new Date()) - 100,
	endYear = getYear(new Date()) + 100,
}: CombinedDatePickerProps) {
	const [internalDate, setInternalDate] = React.useState<Date>(new Date());
	const [popoverOpen, setPopoverOpen] = React.useState(false);

	const date = externalDate ?? internalDate;
	const setDate = externalSetDate ?? setInternalDate;

	const years = Array.from(
		{ length: endYear - startYear + 1 },
		(_, i) => startYear + i
	);

	const handleMonthChange = (month: string) => {
		const newDate = setMonth(date, months.indexOf(month));
		setDate(newDate);
	};

	const handleYearChange = (year: string) => {
		const newDate = setYear(date, parseInt(year));
		setDate(newDate);
	};

	const handleSelect = (selectedDate: Date | undefined) => {
		if (selectedDate) {
			setDate(selectedDate);
			setPopoverOpen(false);
		}
	};

	return (
		<Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					className={cn(
						"w-fit justify-start gap-1 p-2 text-left font-normal",
						!date && "text-muted-foreground",
						className
					)}
				>
					<CalendarIcon className="h-4 w-4" />
					{isValid(date) && date ? (
						format(date, "PPP")
					) : (
						<span>{title || "Pick a date"}</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" side="bottom">
				<div className="flex justify-between p-2">
					<Select
						onValueChange={handleMonthChange}
						value={months[getMonth(date)]}
					>
						<SelectTrigger className="w-[110px]">
							<SelectValue placeholder="Month" />
						</SelectTrigger>
						<SelectContent>
							{months.map((month) => (
								<SelectItem key={month} value={month}>
									{month}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<Select
						onValueChange={handleYearChange}
						value={getYear(date).toString()}
					>
						<SelectTrigger className="w-[110px]">
							<SelectValue placeholder="Year" />
						</SelectTrigger>
						<SelectContent>
							{years.map((year) => (
								<SelectItem key={year} value={year.toString()}>
									{year}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<Calendar
					mode="single"
					selected={date}
					onSelect={handleSelect}
					initialFocus
					month={date}
					onMonthChange={setDate}
					{...(toDate && { to: toDate })}
					disabled={disabled}
				/>
			</PopoverContent>
		</Popover>
	);
}

export default DatePicker;
