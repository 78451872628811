import {
	useGetFormInfo,
	useSubmitFormResponse,
} from "@/src/store/slices/formSlice";
import FormRenderer from "./components/FormRenderer";
import { useParams } from "react-router";
import { Card, CardHeader, CardTitle } from "@/src/components/ui/card";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";

const Forms = () => {
	const { form_id } = useParams();
	const {
		data,
		isLoading: isFormInfoLoading,
		isError: isFormInfoError,
	} = useGetFormInfo(form_id as string);
	const {
		mutate: mutateSubmitForm,
		isSuccess: isFormSubmissionSucessful,
		isPending: isSubmissionPending,
	} = useSubmitFormResponse();

	const onSubmitFormResponses = async (formResponses) => {
		if (!form_id) return false;
		mutateSubmitForm({
			form_id,
			data: { form_response: formResponses },
		});
	};

	if (isFormInfoError) {
		return (
			<div className="flex min-h-screen flex-col justify-center bg-[#E5EEF4] p-4">
				<div className="mx-auto my-auto w-full max-w-sm">
					<Card className="flex h-[30vh] w-full flex-col items-center justify-center border-t-8 border-t-primary">
						<CardHeader className="text-center">
							<CardTitle>Invalid Form Link</CardTitle>
						</CardHeader>
					</Card>
				</div>
			</div>
		);
	}
	return (
		<>
			<RequestIsLoading isLoading={isFormInfoLoading} />
			<div className="flex min-h-screen flex-col justify-center bg-[#E5EEF4] p-4">
				<FormRenderer
					data={data?.data}
					isSubmissionSuccessful={isFormSubmissionSucessful}
					isSubmissionLoading={isSubmissionPending}
					onSubmit={onSubmitFormResponses}
				/>
			</div>
		</>
	);
};

export default Forms;
