import { useEffect, useState } from "react";
import useScheduleStore from "@/src/store/useScheduleStore";
import { IoSearch } from "react-icons/io5";
import { Label } from "@/src/components/ui/label";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { useDebouncedSearch } from "@/src/hooks/useDebouncedSearch";
import CustomConfirmedAppointmentSvg from "./components/svgs/CustomConfirmedAppointmentSvg";

const BusinessLocationSelection = () => {
	const {
		appointmentDetails,
		setAppointmentDetails,
		setStep,
		originalAppointmentDetails,
		setOriginalAppointmentDetails,
	} = useScheduleStore();

	useEffect(() => {
		if (appointmentDetails && !originalAppointmentDetails) {
			setOriginalAppointmentDetails(appointmentDetails);
		}
	}, [
		appointmentDetails,
		originalAppointmentDetails,
		setOriginalAppointmentDetails,
	]);

	const handleLocationSelection = (location) => {
		setAppointmentDetails(location);
		setStep(1.5);
	};

	const handleGoBack = () => {
		setStep(0);
	};

	return (
		<div className="bg-primary-3 flex w-full flex-col gap-y-4  pb-12 font-sans md:h-[100svh] md:overflow-auto lg:h-auto  2xl:h-screen">
			<div className="flex w-full justify-between bg-white font-sans">
				<div className="flex flex-1 items-center border-b-2 border-[#053969] py-5 lg:px-12">
					<button
						onClick={handleGoBack}
						className="h-fit w-fit cursor-pointer p-0"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</button>
					<h1 className="w-full justify-center px-6 text-center text-[15px] font-semibold text-primary md:text-lg">
						Select Location
					</h1>
				</div>
			</div>

			<div className="h-full w-full items-center justify-center gap-6 align-top md:flex md:items-start lg:px-12">
				<div className="border-primary-3 flex w-full flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
					<div className="flex items-center justify-center gap-x-2 rounded-md bg-white py-3 text-lg text-main-2">
						<CustomConfirmedAppointmentSvg width={60} height={60} />
						<p className="text-lg font-semibold text-primary">
							Select a Location
						</p>
					</div>

					<div className="mt-6 flex flex-col gap-y-1">
						<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
							<i className="mgc_building_1_line before:!text-primary" />
							<p className="text-sm font-bold text-main-1">
								{appointmentDetails?.name}
							</p>
						</div>

						<LocationSection
							locations={appointmentDetails?.locations}
							handleClick={handleLocationSelection}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const LocationSection = ({ locations, handleClick }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const debouncedSearchTerm = useDebouncedSearch(searchTerm, 300);

	const filteredLocations = locations?.filter((location) =>
		location?.name
			?.toLowerCase()
			.includes(debouncedSearchTerm.toLowerCase())
	);

	return (
		<div className="flex flex-col gap-y-4 pt-2">
			<div className="relative">
				<span className="absolute left-2 top-2">
					<IoSearch className="text-sm text-[#858C95]" />
				</span>
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					disabled={!locations?.length}
					className="w-full rounded-md border border-[#E5E5E7] py-1 pl-6 text-sm text-[#858C95]"
					placeholder="Quick search by name"
				/>
			</div>

			<div className="flex flex-col gap-y-2">
				<ul className="flex flex-col gap-y-2">
					{locations?.length ? (
						filteredLocations?.map((item) => (
							<li
								key={item?.id}
								className="flex cursor-pointer flex-col space-y-1 rounded-xl bg-primary/5 p-2"
								onClick={() => handleClick(item)}
							>
								<div className="flex items-center justify-between space-x-1">
									<Label className="text-sm font-medium text-[#09090B]">
										{item?.name}
									</Label>
									<ChevronRightIcon />
								</div>
								<p className="line-clamp-2 text-xs text-[#858C95]">
									{item?.address}
								</p>
							</li>
						))
					) : (
						<p className="mt-2 text-center text-sm">
							No Locations Available
						</p>
					)}
				</ul>
			</div>
		</div>
	);
};

export default BusinessLocationSelection;
