import { useEffect, useLayoutEffect, useState } from "react";
import CheckInModal from "./components/CheckInModal";
import { cn } from "@/src/utils/general";
import {
	useGetScheduleData,
	useGetScheduleOptimizerOpenSlot,
} from "@/src/store/slices/scheduleSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import moment from "moment-timezone";
import { ScheduleStatus, userTimezone } from "@/src/utils/constants";
import CancelAppointmentModal from "./components/CancelAppointmentModal";
import useScheduleStore from "@/src/store/useScheduleStore";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import AddToCalendarButton from "./components/AddToCalendarButton";
import ScheduleOptimizerModal from "./components/ScheduleOptimizerModal";
import CustomConfirmedAppointmentSvg from "./components/svgs/CustomConfirmedAppointmentSvg";
import ScheduleChatTab from "./components/ScheduleChatTab";

const AppointmentStatus = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const code = queryParams.get("code");
	const [showOptimizer, setShowOptimizer] = useState(false);
	const [optimizerStatus, setOptimizerStatus] = useState<
		"idle" | "loading" | "success" | "error"
	>("idle");

	const [tabView, setTabView] = useState<0 | 1>(0);
	const { appointment_code } = useParams();
	const navigate = useNavigate();
	const { setAppointmentStatusDetails } = useScheduleStore();
	const { data, isSuccess, isLoading } = useGetScheduleData(
		{
			scheduling_code: appointment_code,
		},
		true
	);

	const track_code = data?.data?.track_code;

	const {
		mutate: acceptSlot,
		isPending: isAcceptOptimizerSlotLoading,
		isSuccess: isSlotAccepted,
		isError: isSlotError,
		data: acceptSlotResponse,
	} = useGetScheduleOptimizerOpenSlot(
		() => {
			setOptimizerStatus("success");
		},
		() => {
			setOptimizerStatus("error");
		}
	);

	const isToday = moment(data?.data?.appointment_date).isSame(
		moment(),
		"day"
	);

	useEffect(() => {
		if (code) {
			setShowOptimizer(true);
			setOptimizerStatus("loading");
			acceptSlot({
				invitation_code: code,
				schedule_code: appointment_code,
			});
		}
	}, [code, acceptSlot, appointment_code]);

	useEffect(() => {
		if (isSuccess && data) {
			setAppointmentStatusDetails(data);
		}
	}, [isSuccess, data, setAppointmentStatusDetails]);

	useEffect(() => {
		if (isSlotAccepted) {
			setOptimizerStatus("success");
		} else if (isSlotError) {
			setOptimizerStatus("error");
		}
	}, [isSlotAccepted, isSlotError]);

	const handleGoToAppointment = () => {
		if (acceptSlotResponse?.data?.appointment_code) {
			navigate(`/schedule/${acceptSlotResponse.data.track_code}/status`, {
				replace: true,
			});
		}
		setShowOptimizer(false);
	};

	const handleModalClose = () => {
		setShowOptimizer(false);
	};

	useLayoutEffect(() => {
		localStorage.setItem(
			"heap-appointment-start-time",
			new Date().getTime().toString()
		);
	}, []);

	return (
		<>
			<div className="flex flex-col gap-y-4 bg-[#F3F3F3] px-4 py-8">
				<div className="flex items-center justify-center gap-x-2 rounded-md bg-white py-3 text-lg text-main-2 ">
					<CustomConfirmedAppointmentSvg width={66} height={50} />
					<h1 className="text-lg font-semibold text-primary">
						{isToday
							? "Today's Appointment"
							: "Appointment Details"}
					</h1>
				</div>

				<div>
					<div className="flex justify-between rounded-tl-[12px]  rounded-tr-[12px]  bg-white">
						<button
							className={cn(
								"w-full border-b border-b-[#858C95]  py-2 text-center",
								{
									"border-b-4 border-b-primary font-semibold text-primary ":
										tabView == 0,
								}
							)}
							onClick={() => setTabView(0)}
						>
							Details
						</button>
						<button
							className={cn(
								"w-full border-b border-b-[#858C95] py-2 text-center ",
								{
									"border-b-4 border-b-primary font-semibold ":
										tabView == 1,
								}
							)}
							onClick={() => setTabView(1)}
						>
							Chat
						</button>
					</div>
					{tabView == 0 && <DetailsTab data={data?.data} />}
					{tabView == 1 && (
						<ScheduleChatTab
							track_code={track_code}
							customer={data?.data?.customer}
						/>
					)}
				</div>
			</div>

			<RequestIsLoading
				isLoading={isLoading || isAcceptOptimizerSlotLoading}
				isWhite
				isFullpage
				size={24}
			/>

			<ScheduleOptimizerModal
				isOpen={showOptimizer}
				onClose={handleModalClose}
				onGoToAppointment={handleGoToAppointment}
				status={optimizerStatus}
			/>
		</>
	);
};

export default AppointmentStatus;

const DetailsTab = (data) => {
	const [isCancelAppointmentOpen, setIsCancelAppointmentOpen] =
		useState(false);
	const { appointment_code } = useParams();
	const isToday = moment(data?.data?.appointment_date).isSame(
		moment(),
		"day"
	);
	const navigate = useNavigate();
	return (
		<>
			<div className="bg-[#fff]">
				<div className=" flex items-center justify-between px-6 pt-2">
					<p className="flex items-center gap-2">
						<i className="mgc_user_4_line before:!text-primary" />
						<span className="text-xs text-[#6D748D]">
							{data?.data?.customer?.full_name}
						</span>
					</p>
					<span
						className={cn(
							"rounded-sm  bg-[#D9294A]/20 px-2 py-0.5 text-xs capitalize",
							{
								"bg-[#D9294A]/20 text-[#C8322B]":
									data?.data?.status ===
									"waiting_to_be_approved",
								"bg-[#D9294A]/30 text-[#C8322B]":
									data?.data?.status === "expired",
								"bg-[#ABDBF7] text-[#005893]":
									data?.data?.status === "approved",
							}
						)}
					>
						{ScheduleStatus[data?.data?.status]}
					</span>
				</div>

				<div className="px-6 py-8">
					<div className=" flex w-full flex-col items-center justify-between rounded-xl bg-primary/5 px-4 pb-4 pt-2">
						{data?.data?.status !== "expired" && (
							<div className="flex w-full items-center justify-between ">
								<button
									className="text-xs text-primary underline underline-offset-1"
									onClick={() =>
										navigate(
											`/schedule/${appointment_code}/reschedule`
										)
									}
								>
									Reschedule
								</button>
								<button
									className="text-xs text-[#C8322B] underline underline-offset-1"
									onClick={() =>
										setIsCancelAppointmentOpen(true)
									}
								>
									Cancel Appointment
								</button>
							</div>
						)}

						<div className="flex flex-col items-center  gap-2 self-center">
							<div className="flex items-center gap-x-2 self-center pt-4">
								<span className="flex items-center justify-center rounded-full bg-primary/20 p-1">
									<i className="mgc_schedule_line before:!text-primary" />
								</span>
								<h2 className="text-sm font-semibold text-primary">
									{data?.data?.appointment_date
										? moment(
												data?.data?.appointment_date
											)?.format("Do MMMM YYYY")
										: "N/A"}
								</h2>
							</div>

							<div className="flex gap-x-2 self-center">
								<p>
									<span className="text-[22px] font-bold text-primary">
										{/* {data?.data?.appointment_date
											? moment
													.utc(
														data?.data
															?.appointment_date
													)
													?.format("h:mm")
											: "N/A"}{" "} */}
										{/* 11:30 */}
										{data?.data?.appointment_date
											? moment
													.tz(
														data?.data
															?.appointment_date,
														userTimezone
													)
													.format("h:mm")
											: "N/A"}
									</span>
									<sub className="font-light ">
										{" "}
										{data?.data?.appointment_date
											? moment
													.tz(
														data?.data
															?.appointment_date,
														userTimezone
													)
													.format("a")
											: "N/A"}
										{/* {data?.data?.appointment_date
											? moment(
													data?.data?.appointment_date
												)?.format("a")
											: "N/A"}{" "} */}
									</sub>
								</p>{" "}
								<span>
									<sub>--</sub>{" "}
								</span>
								<p>
									<span className="text-[22px] font-bold text-primary">
										{data?.data?.end_at
											? moment
													.tz(
														data?.data?.end_at,
														userTimezone
													)
													.format("h:mm")
											: "N/A"}
									</span>
									<sub className="font-light ">
										{data?.data?.end_at
											? moment
													.tz(
														data?.data?.end_at,
														userTimezone
													)
													.format("a")
											: "N/A"}
									</sub>
								</p>
							</div>
						</div>
					</div>
					<div className="py-8">
						<ul className="flex flex-col gap-y-2">
							<li className="flex items-center space-x-2">
								<i className="mgc_store_line before:!text-primary" />
								<p className="text-xs font-bold text-main-1">
									{data?.data?.station?.name}
								</p>
							</li>
							<li className="flex items-center space-x-2">
								<i className="mgc_directions_2_line before:!text-primary" />
								<p className="text-xs text-main-1">
									{data?.data?.service?.name ?? "N/A"}
								</p>
							</li>
							<li className="flex items-center space-x-2">
								<i className="mgc_building_1_line before:!text-primary" />
								<p className="text-xs font-bold text-main-1">
									{data?.data?.station?.business?.name}
								</p>
							</li>
							<li className="flex items-center space-x-2">
								<i className="mgc_location_line before:!text-primary" />
								<p className="text-xs text-[#6D748D]">
									{data?.data?.station?.location?.address}
								</p>
							</li>
						</ul>
					</div>
					<div className="border-t border-[#E5E5E7] py-3">
						{isToday ? (
							<div className="flex flex-col gap-y-2 ">
								{data?.data?.status !== "expired" && (
									<CheckInModal data={data} />
								)}

								{/* <Button
									type="button"
									variant="outline-primary"
									className="w-full "
									disabled={true}
									onClick={() =>
										navigate(
											`/schedule/${appointment_code}/intake-form`
										)
									}
								>
									{isFormFilled ? "View Form" : "Fill Form "}
								</Button> */}
							</div>
						) : (
							<div className="flex flex-col gap-y-2">
								<AddToCalendarButton data={data?.data} />
								{/* <Button
									type="button"
									variant="outline-primary"
									className="w-full"
									// disabled={data?.data?.status == "expired"}
									disabled={true} // todo: this would be disabled until mikiyas fixes this flow
									onClick={() =>
										navigate(
											`/schedule/${appointment_code}/intake-form`
										)
									}
								>
									{isFormFilled ? "View Form" : "Fill Form "}
								</Button> */}
							</div>
						)}
					</div>
				</div>
			</div>
			<CancelAppointmentModal
				data={data.data}
				appointment_code={appointment_code}
				isOpen={isCancelAppointmentOpen}
				setIsOpen={setIsCancelAppointmentOpen}
			/>
		</>
	);
};
