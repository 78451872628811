import $http from "../xhr";

export const APIVersion2GetFormInfo = async (form_id: string): Promise<any> =>
	$http.get(`v2/form/${form_id}`).then((res) => res.data);

export const APIVersion2SubmitFormResponses = async (
	form_id,
	data
): Promise<any> =>
	$http.post(`v2/form/${form_id}/add-response`, data).then((res) => res.data);
