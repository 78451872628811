import { CustomIntake } from "@/src/types/custom-intakes";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import Checkbox from "../ui-extended/checkbox";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { cn } from "@/src/utils/general";
import { Controller } from "react-hook-form";
import { format } from "date-fns";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import MultiSelect from "../ui-extended/multi-select";
import { Option } from "@/src/types/waitlist";
import { z } from "zod";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";

const CustomIntakeField: React.FC<{
	intake: CustomIntake;
	register: any;
	errors: any;
	control: any;
}> = ({ intake, register, errors, control }) => {
	switch (intake.type) {
		case "text":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					{intake.long_text ? (
						<Textarea
							{...register(`custom_intakes.${intake.key}`)}
							aria-label={intake.key}
							id={intake.key}
						/>
					) : (
						<Input
							{...register(`custom_intakes.${intake.key}`)}
							className="text-black"
							aria-label={intake.key}
							id={intake.key}
						/>
					)}
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "numeric":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<div className="flex items-center space-x-2.5">
						<Input
							type="number"
							{...register(`custom_intakes.${intake.key}`)}
							aria-label={intake.key}
							id={intake.key}
						/>
					</div>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "boolean":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						rules={{ required: `${intake.name} is required` }}
						render={({ field }) => (
							<RadioGroup
								className="flex items-center space-x-2.5"
								value={field?.value?.toString()}
								onValueChange={(value) =>
									field.onChange(value === "true")
								}
							>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="true"
										id={`${intake.key}-true`}
										className="border-[#D9D9D9]"
									/>
									<Label
										htmlFor={`${intake.key}-true`}
										className="text-sm"
									>
										True
									</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="false"
										id={`${intake.key}-false`}
										className="border-[#D9D9D9]"
									/>
									<Label
										htmlFor={`${intake.key}-false`}
										className="text-sm"
									>
										False
									</Label>
								</div>
							</RadioGroup>
						)}
					/>

					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);

		case "date":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						render={({ field }) => (
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant={"outline"}
										className={cn(
											"w-[280px] justify-start text-left font-normal",
											!field.value &&
												"text-muted-foreground"
										)}
									>
										<i className="mgc_calendar_line mr-2 before:!text-main-1" />
										{field.value ? (
											format(field.value, "PPP")
										) : (
											<span>Pick a date</span>
										)}
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0">
									<Calendar
										mode="single"
										selected={field.value}
										onSelect={field.onChange}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						)}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "date_range":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						render={({ field }) => (
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant={"outline"}
										className={cn(
											"w-full justify-start text-left font-normal",
											!field.value &&
												"text-muted-foreground"
										)}
									>
										<i className="mgc_calendar_line mr-2 before:!text-main-1" />
										{field.value?.from ? (
											field.value.to ? (
												<>
													{format(
														field.value.from,
														"PPP"
													)}{" "}
													-{" "}
													{format(
														field.value.to,
														"PPP"
													)}
												</>
											) : (
												format(field.value.from, "PPP")
											)
										) : (
											<span>Pick a date range</span>
										)}
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0">
									<Calendar
										mode="range"
										selected={field.value}
										onSelect={field.onChange}
										numberOfMonths={2}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						)}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);

		case "dropdown":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						render={({ field }) =>
							intake.allow_multiple ? (
								<MultiSelect
									options={intake.options.map((option) => ({
										label: option.value,
										value: option.id,
									}))}
									placeholder="Select options"
									selected={field.value || []}
									onSelect={(selected) =>
										field.onChange(selected)
									}
									onUnselect={(option) => {
										const newSelected = (
											field.value || []
										).filter(
											(item: Option) =>
												item.value !== option.value
										);
										field.onChange(newSelected);
									}}
									checkBoxed={true}
									error={errors.custom_intakes?.[intake.key]}
								/>
							) : (
								<Select
									onValueChange={(value) =>
										field.onChange([
											{ label: value, value },
										])
									}
									value={field.value?.[0]?.value || ""}
								>
									<SelectTrigger className="w-full">
										<SelectValue placeholder="Select an option" />
									</SelectTrigger>
									<SelectContent>
										{intake.options.map((option) => (
											<SelectItem
												key={option.id}
												value={option.id}
											>
												{option.value}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							)
						}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "enum":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						rules={{
							required: intake.field_requirement
								? `${intake.name} is required`
								: false,
						}}
						render={({ field }) => (
							<Select
								value={field.value}
								onValueChange={field.onChange}
							>
								<SelectTrigger className="w-full">
									<SelectValue
										placeholder={`Select ${intake.name}`}
									/>
								</SelectTrigger>
								<SelectContent>
									{intake.values.map((option, index) => (
										<SelectItem key={index} value={option}>
											{option}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						)}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "checkbox":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					{intake.allow_multiple ? (
						<Controller
							name={`custom_intakes.${intake.key}`}
							control={control}
							rules={{ required: `${intake.name} is required` }}
							defaultValue={[]} // Default value is an empty array for multiple selections
							render={({ field }) => (
								<ul className="flex flex-col  gap-y-1">
									{intake.options.map((option, index) => (
										<li
											key={index}
											className="flex items-center space-x-2"
										>
											<Checkbox
												// No value prop here, we rely on checked and onChange
												id={field.value}
												isChecked={field.value.includes(
													option
												)}
												className="rounded-sm"
												handleCheckboxChange={() => {
													if (
														field.value.includes(
															option
														)
													) {
														// Remove value from array if already selected
														field.onChange(
															field.value.filter(
																(val) =>
																	val !==
																	option
															)
														);
													} else {
														// Add value to array if not selected
														field.onChange([
															...field.value,
															option,
														]);
													}
												}}
											/>
											<Label className="text-sm font-normal text-[#1E1E1E]">
												{option}
											</Label>
										</li>
									))}
								</ul>
							)}
						/>
					) : (
						<Controller
							name={`custom_intakes.${intake.key}`}
							control={control}
							rules={{ required: `${intake.name} is required` }}
							render={({ field }) => (
								<RadioGroup
									value={field.value}
									onValueChange={(value) =>
										field.onChange([value])
									}
								>
									<ul className="flex flex-col space-y-1.5">
										{intake.options.map((option, index) => (
											<li
												key={index}
												className="flex items-center space-x-2"
											>
												<RadioGroupItem
													value={option}
													id={`${intake.key}-${index}`}
													checked={
														field.value
															? field.value.includes(
																	option
																)
															: undefined
													}
													className="border-[#D9D9D9]"
												/>
												<Label
													htmlFor={`${intake.key}-${index}`}
													className="text-sm font-normal text-[#1E1E1E]"
												>
													{option}
												</Label>
											</li>
										))}
									</ul>
								</RadioGroup>
							)}
						/>
					)}

					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "attachment":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Input
						type="file"
						{...register(`custom_intakes.${intake.key}`)}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "infoImage":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} notRequired />
					<img src={intake.image} alt={intake.name} />
				</div>
			);
		case "infoText":
			return (
				<div className="space-y-1.5">
					<CustomIntakeFieldLabel intake={intake} notRequired />
					<p className="text-sm text-main-1">{intake.subtitle}</p>
				</div>
			);
		default:
			return null;
	}
};

export default CustomIntakeField;

const CustomIntakeFieldLabel: React.FC<{
	intake: CustomIntake;
	notRequired?: boolean;
}> = ({ intake, notRequired }) => {
	return (
		<div className="flex flex-col">
			<Label className="text-sm font-medium" htmlFor={intake.key}>
				{intake.name}{" "}
				{intake.field_requirement === "yes" && !notRequired && (
					<span className="text-destructive">*</span>
				)}
			</Label>
			<small className="text-xs text-[#757575]">{intake.subtitle}</small>
		</div>
	);
};

export const createJoinWaitlistSchema = (customIntakes: CustomIntake[]) => {
	const customIntakeSchema: Record<string, z.ZodTypeAny> = {};

	customIntakes.forEach((intake) => {
		let fieldSchema: z.ZodTypeAny;

		switch (intake.type) {
			case "text":
				fieldSchema = z.string();
				break;
			case "numeric":
				fieldSchema = z.string();
				break;
			case "boolean":
				fieldSchema = z.boolean();
				break;
			case "date":
				fieldSchema = z.date();
				break;
			case "date_range":
				fieldSchema = z.object({
					from: z.date(),
					to: z.date(),
				});
				break;
			case "dropdown":
				fieldSchema = z.array(
					z.object({
						label: z.string(),
						value: z.string(),
					})
				);
				break;
			case "enum":
				fieldSchema = z.string();
				break;
			case "checkbox":
				fieldSchema = z
					.array(z.string())
					.nonempty(`${intake.name} is required`);
				break;

			case "attachment":
				fieldSchema = z.instanceof(File);
				break;
			default:
				fieldSchema = z.any();
		}

		if (intake.field_requirement === "yes") {
			fieldSchema = z.preprocess(
				(val) => {
					if (val === "" || val === null || val === undefined) {
						return undefined;
					}
					return val;
				},
				fieldSchema.refine((val) => val !== undefined, {
					message: `${intake.name} is required`,
				})
			);
		} else {
			fieldSchema = fieldSchema.optional();
		}

		customIntakeSchema[intake.key] = fieldSchema;
	});

	return z.object({
		full_name: z.string().min(1, "Full name is required"),
		phone_number: z.string().min(1, "Phone number is required"),
		email: z.string().email().optional().or(z.literal("")),
		custom_intakes: z.object(customIntakeSchema).optional(),
	});
};
