import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/src/components/ui/dialog";
import { useUpdateWaitlist } from "@/src/store/slices/waitlist";
import {
	UpdateWaitlistActionType,
	UpdateWaitlistSchema,
} from "@/src/types/waitlist";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import useCustomToast from "../CustomToast";
import { LoaderButton } from "../ui-extended/loader-button";
import { Button } from "../ui/button";
import useUserStore from "@/src/store/useUserStore";

const LeaveWaitlistModal: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const { waitlist_code } = useParams();
	const customToast = useCustomToast();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const updateWaitlistMutation = useUpdateWaitlist(
		() => {
			customToast("You have successfully left the waitlist. 🫡", {
				id: "update-waitlist",
			});
			setIsOpen(false);
			navigate("/waitlist/" + waitlist_code + "/leave", {
				replace: true,
			});
		},
		() => {
			customToast("Unable to leave waitlist 🤕 ", {
				id: "update-waitlist",
				type: "error",
			});
			queryClient.invalidateQueries({ queryKey: ["waitlist-info"] });
		}
	);

	const {
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<UpdateWaitlistActionType>({
		defaultValues: { action: "cancel" },
		resolver: zodResolver(UpdateWaitlistSchema),
	});

	const onSubmit: SubmitHandler<UpdateWaitlistActionType> = (data) => {
		updateWaitlistMutation.mutate(data);
	};

	return (
		<Dialog
			open={isOpen}
			onOpenChange={(state) => {
				if (!updateWaitlistMutation.isPending) {
					setIsOpen(state);
					if (!state) reset();
				}
			}}
		>
			<DialogTrigger className="w-full">
				<Button
					className="w-full"
					variant="outline-destructive"
					type="button"
				>
					Leave Waitlist
				</Button>
			</DialogTrigger>

			<DialogContent className="flex w-full max-w-[360px] rounded-[10px] p-6">
				<form onSubmit={handleSubmit(onSubmit)} className="flex gap-2">
					<i className="mgc_information_line mt-1.5 text-[20px] before:!text-main-4" />
					<div className="flex-1">
						<div className="flex flex-col space-y-2">
							<DialogHeader>
								<DialogTitle className="flex items-center justify-between">
									<h1 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px]">
										Leave Waitlist?
									</h1>
								</DialogTitle>
							</DialogHeader>
							<p className="text-sm tracking-[-0.1px] text-main-7">
								Are you sure you want to leave the waitlist?
								<br />
								<br />
								If you leave the waitlist, all your information
								will be removed.{" "}
								<span className="font-semibold">
									This action can&apos;t be undone.
								</span>
							</p>
						</div>
						{errors.root?.message && (
							<small className="text-sm text-destructive">
								{errors.root?.message as string}
							</small>
						)}
						<DialogFooter className="mt-6 flex w-full !flex-row space-x-2.5">
							<Button
								className="flex-1"
								variant="ghost"
								onClick={() => {
									if (!updateWaitlistMutation.isPending) {
										setIsOpen(false);
										reset();
									}
								}}
								disabled={updateWaitlistMutation.isPending}
							>
								Cancel
							</Button>
							<LoaderButton
								className="flex-1 text-white"
								type="submit"
								variant="destructive"
								loaderSize={20}
								onClick={() => handleSubmit(onSubmit)}
								loading={updateWaitlistMutation.isPending}
								disabled={updateWaitlistMutation.isPending}
							>
								Leave Waitlist
							</LoaderButton>
						</DialogFooter>
					</div>
					<DialogClose
						className="flex items-start"
						aria-label="Close dialog"
						title="Close dialog"
					>
						<i className="mgc_close_line before:!text-main-4 " />
						<span className="sr-only">Close</span>
					</DialogClose>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default LeaveWaitlistModal;
