import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import { Button } from "@/src/components/ui/button";
import UserJoinInformation from "@/src/components/Waitlist/UserJoinInformation";
import { useGetStationJoinInfo } from "@/src/store/slices/waitlist";
import useUserStore from "@/src/store/useUserStore";
import { JoinWaitlistSingleStation } from "@/src/types/waitlist";
import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router";
import WaitlistClosed from "./WaitlistClosed";
import WaitlistOperatingHoursClosed from "./WaitlistOperatingHoursClosed";

const JoinWaitList: React.FC = () => {
	const navigate = useNavigate();
	const { join_code } = useParams();
	const setJoinCode = useUserStore((s) => s.setJoinCode);
	const getJoinStationInfoQuery = useGetStationJoinInfo();
	const data = getJoinStationInfoQuery.data as JoinWaitlistSingleStation;
	const [joinWaitlistStage, setJoinWaitlistStage] = React.useState(1);
	const estimatedWaitTime = {
		hour: data
			? Math.floor((Math.abs(data?.data?.estimated_wait_time) ?? 0) / 60)
			: 0,
		minute: data ? (Math.abs(data?.data.estimated_wait_time) ?? 0) % 60 : 0,
	};

	useLayoutEffect(() => {
		localStorage.setItem("heap-appointment-start-time", "0");
		localStorage.setItem("heap-waitlist-start-time", "0");
		localStorage.setItem(
			"heap-waitlist-start-time",
			new Date().getTime().toString()
		);
	}, []);

	useEffect(() => {
		setJoinCode(join_code ?? "");
	}, []);

	if (!join_code) navigate("/");
	if (
		!getJoinStationInfoQuery.isLoading &&
		getJoinStationInfoQuery?.error?.response?.data.error ===
			"NO_WORKING_HOURS"
	) {
		return (
			<WaitlistOperatingHoursClosed
				query={getJoinStationInfoQuery?.error}
			/>
		);
	}
	if (
		!getJoinStationInfoQuery.isLoading &&
		getJoinStationInfoQuery?.error?.response?.data.error
	) {
		return <WaitlistClosed />;
	}

	return (
		<>
			{!getJoinStationInfoQuery.isLoading ? (
				joinWaitlistStage === 1 ? (
					<section className="mt-14 flex flex-col items-center space-y-16 px-4">
						<img
							src="/assets/welcome.svg"
							alt="Welcome to the waitlist registration page - an illustration showing the waitlist process"
							className="max-w-[246px]"
						/>
						<div className="w-full rounded-xl bg-white p-6">
							<div className="flex flex-col space-y-5">
								<h1 className="text-[28px] font-bold leading-[140%] tracking-[-0.14px] text-main-1">
									Join the Waitlist
								</h1>
								<ul className="flex flex-col space-y-2">
									<li className="flex items-center space-x-2">
										<i className="mgc_shop_line before:!text-primary" />
										<p className="font-bold capitalize text-main-1">
											{data?.data.station.name}
										</p>
									</li>
									<li className="flex items-center space-x-2">
										<i className="mgc_building_1_line before:!text-primary" />
										<p className="capitalize text-main-1">
											{data?.data.station.business.name}
										</p>
									</li>
									<li className="flex items-center space-x-2">
										<i className="mgc_building_1_line before:!text-primary" />
										<p className="capitalize text-main-1">
											{data?.data.station.location.name}
										</p>
									</li>
									<li className="flex items-center space-x-2">
										<i className="mgc_location_line before:!text-primary" />
										<p className="capitalize text-[#6D748D]">
											{
												data?.data.station.location
													.address
											}
										</p>
									</li>
								</ul>
							</div>
							<ul className="mt-12 flex flex-col space-y-2 rounded-lg bg-[#F3F3F3] p-3">
								<li className="flex items-center space-x-2">
									<i className="mgc_group_2_line before:!text-primary" />
									<p className="text-primary">
										<span className="font-semibold">
											{data?.data.waitlist_count}{" "}
										</span>
										Waiting
									</p>
								</li>
								<li className="flex items-center space-x-2">
									<i className="mgc_stopwatch_line before:!text-primary" />
									<p className="text-primary">
										<span className="font-semibold">
											{estimatedWaitTime.hour} h{" "}
											{estimatedWaitTime.minute} mins{" "}
										</span>{" "}
										Estimated Wait Time
									</p>
								</li>
							</ul>
							<Button
								className="mt-8 w-full"
								type="button"
								onClick={() => {
									setJoinWaitlistStage(2);
								}}
							>
								Join Waitlist
							</Button>
						</div>
					</section>
				) : (
					<UserJoinInformation />
				)
			) : (
				<div></div>
			)}

			<RequestIsLoading
				isLoading={getJoinStationInfoQuery.isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	);
};

export default JoinWaitList;
