import {
	APIVersion2AcceptOpenSlot,
	APIVersion2CancelReservedTimeslot,
	APIVersion2CompleteReservation,
	APIVersion2CreateAppointment,
	APIVersion2GetMessageConversations,
	APIVersion2GetScheduleCodeCustomFields,
	APIVersion2GetScheduleLocationInfo,
	APIVersion2GetScheduleStationInfo,
	APIVersion2GetScheduleStatus,
	APIVersion2GetServiceAvailableTimeSlotsForSchedule,
	APIVersion2GetServiceDetail,
	APIVersion2GetSlotTokenInformation,
	APIVersion2GetStationAvailableTimeSlotsForSchedule,
	APIVersion2GetStationBasedTimeslots,
	APIVersion2IncreaseReservationTime,
	APIVersion2ReserveTimeslot,
	APIVersion2SendMessageConversation,
	APIVersion2UpdateAppointment,
	APIVersion2VerifyCustomer,
} from "@/src/http/v1";
import {
	CustomerValidationResponse,
	ScheduleInfoResponse,
	ScheduleStationResponse,
} from "@/src/types/schedule";
import { useMutation, useQuery } from "@tanstack/react-query";
import useScheduleStore from "../useScheduleStore";
import { AxiosError, AxiosResponse } from "axios";
import useCustomToast from "@/src/components/CustomToast";
import React from "react";

export const useScheduleStationInfo = (data: {
	scheduling_code?: string;
	station_id?: string;
}) => {
	return useQuery<ScheduleStationResponse>({
		queryKey: ["scheduleStationInfo", data],
		queryFn: () => APIVersion2GetScheduleStationInfo(data),
	});
};
export const useGetScheduleInfo = (data: {
	scheduling_code?: string;
	station_id?: string;
}) => {
	return useQuery<ScheduleInfoResponse>({
		queryKey: ["scheduleStationInfo", data],
		queryFn: () => APIVersion2GetScheduleStationInfo(data),
	});
};

export const useScheduleLocationInfo = (data: {
	scheduling_code?: string;
	location_id?: string;
}) => {
	return useQuery({
		queryKey: ["scheduleLocationInfo", data],
		queryFn: () => APIVersion2GetScheduleLocationInfo(data),
	});
};

export const useGetScheduleData = (
	data: { scheduling_code?: string },
	enabled
) => {
	const { setAppointmentPatientDetails } = useScheduleStore((s) => ({
		setAppointmentPatientDetails: s.setAppointmentPatientDetails,
	}));
	const getScheduleDataQuery = useQuery({
		queryKey: ["scheduleStatus", data],
		queryFn: () => APIVersion2GetScheduleStatus(data),
		enabled,
	});

	React.useEffect(() => {
		if (getScheduleDataQuery.data) {
			setAppointmentPatientDetails(
				getScheduleDataQuery?.data?.data?.customer
			);
		}
	}, [getScheduleDataQuery.data]);

	return getScheduleDataQuery;
};

export const useGetScheduleOptimizerOpenSlot = (onSuccess, onError) => {
	return useMutation({
		mutationKey: ["schedule-optimizer-accept"],
		mutationFn: (data: {
			invitation_code?: string;
			schedule_code?: string;
		}) => APIVersion2AcceptOpenSlot(data),
		onSuccess: onSuccess,
		onError: onError,
	});
};
//Would be redundant soon
export const useStationAvailableTimeSlots = (
	stationId: number | undefined,
	data: {
		appointment_type_id?: number;
		date: string | undefined;
	}
) => {
	return useQuery({
		queryKey: ["stationAvailableTimeSlots", stationId, data],
		queryFn: () =>
			APIVersion2GetStationAvailableTimeSlotsForSchedule(stationId, data),
	});
};

export const useGetServiceDetails = (
	serviceId: number | undefined,
	data: {
		location_id?: number;
		stationId?: number;
		appointment_type_id?: number;
		date: string | undefined;
		customer_verification_token?: string;
	}
) => {
	return useQuery({
		queryKey: ["serviceDetail", serviceId, data],
		queryFn: () => APIVersion2GetServiceDetail(serviceId, data),
		refetchOnMount: "always",
	});
};

export const useGetStationBasedTimeslots = (
	serviceId: number | undefined,
	data: {
		location_id: number | undefined;
		date?: string | undefined;
		start_time: string;
		scheduling_code: string;
		customer_verification_token?: string;
	},
	enabled: boolean
) => {
	return useQuery({
		queryKey: ["stationBasedTimeSlots", serviceId, data],
		queryFn: () => APIVersion2GetStationBasedTimeslots(serviceId, data),
		enabled: enabled,
	});
};
export const useGetServiceAvailableTimeSlots = (data: {
	serviceId?: number;
	locationId?: number;
	stationId?: number;
	appointment_type_id?: number;
	customer_verification_token?: string;
	date: string | undefined;
}) => {
	return useQuery({
		queryKey: ["serviceAvailableTimeSlots", data],
		queryFn: () => APIVersion2GetServiceAvailableTimeSlotsForSchedule(data),
		refetchOnMount: true,
	});
};

export const useGetScheduleCodeCustomFields = (scheduling_code: string) => {
	return useQuery({
		queryKey: ["scheduleCodeCustomFields", scheduling_code],
		queryFn: () => APIVersion2GetScheduleCodeCustomFields(scheduling_code),
	});
};

export const useGetSlotTokenInformation = (slot_token: string) => {
	return useQuery({
		queryKey: ["slot_token", slot_token],
		queryFn: () => APIVersion2GetSlotTokenInformation(slot_token),
	});
};

export const useCreateAppointment = () => {
	return useMutation({
		mutationFn: ({
			scheduling_code,
			data,
		}: {
			scheduling_code: string;
			data: {
				customer_verification_token?: string;
				custom_intakes?: Record<string, any> | undefined;
				full_name?: string;
				phone_number?: string;
				email?: string | null;
				date: string;
				start_time: string;
				is_on_notification_waitlist: 0 | 1;
			};
		}) => APIVersion2CreateAppointment(scheduling_code, data),
	});
};

export const useUpdateAppointment = () => {
	return useMutation<AxiosResponse, AxiosError, any>({
		mutationFn: ({
			appointmentId,
			data,
		}: {
			appointmentId: string;
			data: {
				reschedule_date?: string;
				reschedule_time?: string;
				action:
					| "done"
					| "cancel"
					| "reschedule"
					| "update_custom_intakes"
					| "check_in"
					| "undo_checkin";
				custom_intakes?: Record<string, any>;
			};
		}) => APIVersion2UpdateAppointment(appointmentId, data),
	});
};

export const useVerifyCustomer = ({
	onSuccess = () => {},
	onError = () => {},
}: {
	onSuccess?: (data: CustomerValidationResponse) => void;
	onError?: (error: AxiosError) => void;
}) => {
	return useMutation({
		mutationFn: ({
			schedule_code,
			data,
		}: {
			schedule_code: string;
			data: { [x: string]: any };
		}) => APIVersion2VerifyCustomer(schedule_code, data),
		onError: onError,
		onSuccess: onSuccess,
	});
};

export const useReserveTimeslot = () => {
	const toast = useCustomToast();
	const setTemporarySlotToken = useScheduleStore(
		(s) => s.setTemporarySlotToken
	);
	return useMutation({
		mutationFn: ({
			scheduling_code,
			data,
		}: {
			scheduling_code: string;
			data: {
				schedule_code: string;
				customer_verification_token?: string;
				is_on_notification_waitlist: boolean;
				service_id?: number;
				appointment_method_id?: number;
				date: string;
				start_time: string;
				full_name?: string;
				phone_number?: string;
				email?: string | null;
			};
		}) => APIVersion2ReserveTimeslot(scheduling_code, data),
		onError: (error: AxiosError) => {
			setTemporarySlotToken(undefined);
			if (
				error.response?.data &&
				(error.response.data as { message: string }).message ===
					"validation errors"
			) {
				const errorData = error.response.data as {
					errors: Record<string, string[]>;
					message: string;
				};

				Object.entries(errorData.errors).forEach(
					([field, messages]) => {
						messages.forEach((message, index) => {
							toast(message, {
								id: `${field}-${index}`,
								type: "error",
								duration: 2500,
							});
						});
					}
				);
			} else {
				const errorMessage =
					error.response &&
					(error.response.data as { message: string }).message;
				toast(errorMessage || "something went wrong", {
					id: "token-error",
					type: "error",
					duration: 2500,
				});
			}
		},
	});
};

export const useCompleteReservation = ({
	onSuccess,
}: {
	onSuccess: (data: AxiosResponse) => void;
}) => {
	const { setAppointmentPatientDetails } = useScheduleStore((s) => ({
		setAppointmentPatientDetails: s.setAppointmentPatientDetails,
	}));

	return useMutation({
		mutationFn: ({ slot_token, data }: { slot_token: string; data }) =>
			APIVersion2CompleteReservation(slot_token, data),
		onSuccess: (data) => {
			onSuccess(data);
			if (data && data?.data?.customer)
				setAppointmentPatientDetails(data);
		},
	});
};
export const useIncreaseReservedTime = ({
	onSuccess,
}: {
	onSuccess: (data: AxiosResponse) => void;
}) => {
	const toast = useCustomToast();
	// const setTemporarySlotToken = useScheduleStore(
	// 	(s) => s.setTemporarySlotToken
	// ); //todo: use this to set to undefined if token has expired when forms are added
	return useMutation({
		mutationFn: ({ slot_token }: { slot_token: string }) =>
			APIVersion2IncreaseReservationTime(slot_token),
		onSuccess: (response: AxiosResponse) => {
			onSuccess(response);
		},
		onError: (error: AxiosError) => {
			// setTemporarySlotToken(undefined);
			if (
				error.response?.data &&
				(error.response.data as { message: string }).message ===
					"validation errors"
			) {
				const errorData = error.response.data as {
					errors: Record<string, string[]>;
					message: string;
				};

				Object.entries(errorData.errors).forEach(
					([field, messages]) => {
						messages.forEach((message, index) => {
							toast(message, {
								id: `${field}-${index}`,
								type: "error",
								duration: 2500,
							});
						});
					}
				);
			} else {
				const errorMessage =
					error.response &&
					(error.response.data as { message: string }).message;
				toast(errorMessage || "token expired ", {
					id: "token-error",
					type: "error",
					duration: 2500,
				});
			}
		},
	});
};

export const useCancelReservedSlot = ({
	onSuccess,
	onError,
}: {
	onSuccess: (data: AxiosResponse) => void;
	onError: (error: AxiosError) => void;
}) => {
	const toast = useCustomToast();
	return useMutation({
		mutationFn: ({ slot_token }: { slot_token: string }) =>
			APIVersion2CancelReservedTimeslot(slot_token),
		onSuccess: (response: AxiosResponse) => {
			onSuccess(response);
		},
		onError: (error: AxiosError) => {
			// setTemporarySlotToken(undefined);
			if (
				error.response?.data &&
				(error.response.data as { message: string }).message ===
					"validation errors"
			) {
				const errorData = error.response.data as {
					errors: Record<string, string[]>;
					message: string;
				};

				Object.entries(errorData.errors).forEach(
					([field, messages]) => {
						messages.forEach((message, index) => {
							toast(message, {
								id: `${field}-${index}`,
								type: "error",
								duration: 2500,
							});
						});
					}
				);
			} else {
				const errorMessage =
					error.response &&
					(error.response.data as { message: string }).message;
				toast(
					errorMessage ||
						"something went wrong cancelling your slot ",
					{
						id: "token-error",
						type: "error",
						duration: 2500,
					}
				);
			}
			onError(error);
		},
	});
};

export const GetPatientMessageConversationsSlice = (appointment_code) => {
	return useQuery<{ data: any }, Error, any>({
		queryKey: ["get-patient-conversations-", appointment_code],
		queryFn: () =>
			APIVersion2GetMessageConversations({
				appointment_code: appointment_code,
			}),
	});
};

export const SendPatientMessageConversationSlice = () => {
	return useMutation<
		AxiosResponse,
		Error,
		{ message: string; appointment_code: string }
	>({
		mutationFn: (data) => APIVersion2SendMessageConversation(data),
		onError: (error: Error) => console.error(error),
	});
};
