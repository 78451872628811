import Pusher from "pusher-js";
import { PUSHER_KEY } from "../utils/constants";

Pusher.logToConsole = true;

const pusher = new Pusher(PUSHER_KEY, {
	cluster: "us2",
});

export default pusher;
