import { cn, getInitials } from "@/src/utils/general";
import React from "react";
import { Input } from "../ui/input";

const ViewWaitlistChat = () => {
	return (
		<form className="flex min-h-[300px] flex-col px-3.5">
			<div className="mb-2.5 flex flex-1 flex-col">
				<div className="flex-1">
					<ul className="mt-3 flex h-[320px] flex-col space-y-4 rounded-xl">
						{dummyMessages.map((item, idx) => (
							<IndividualMessage key={idx} {...item} />
						))}
					</ul>
				</div>
				<Input
					className="shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.15)]"
					placeholder="Enter Here"
				/>
			</div>
			<p className="border-t border-t-main-3 px-2.5 py-3 text-xs leading-[14px] tracking-[-0.2px] text-[#6D748D]">
				Note: Wait times are estimates and may vary. We aim for
				first-come, first-served, but exceptions may occur based on
				specific situations and management&apos;s discretion. Thank you
				for your understanding.
			</p>
		</form>
	);
};

export default ViewWaitlistChat;

const IndividualMessage: React.FC<{
	id: number;
	senderName: string;
	message: string;
	deliveryStatus: string;
	date: string;
	time: string;
	isMe?: boolean;
}> = ({ senderName, message, deliveryStatus, date, time, isMe }) => {
	return (
		<li
			className={cn("flex size-fit items-start gap-x-1.5", {
				"ml-auto flex flex-row-reverse": isMe,
			})}
		>
			<p
				className={cn(
					"size-fit rounded-xl bg-[#D5D5D5] px-2 py-1 font-semibold text-[#323539]",
					{ "bg-primary text-[#E5E5E7] ": isMe }
				)}
			>
				{getInitials(senderName, true)}
			</p>
			<div className="space-y-0.5">
				<p
					className={cn(
						"rounded-xl bg-[#F6F6F7] px-3.5 py-1 text-[13px] leading-[18px] tracking-[-0.1px]",
						{ "bg-[#EEF5FA] text-[#323539]": isMe }
					)}
				>
					{message}
				</p>
				<div
					className={cn(
						"flex items-center space-x-1.5 text-xs tracking-[-1%] text-[#858C95]",
						{ "pl-3.5": !isMe, "justify-end pr-3.5 ": isMe }
					)}
				>
					<p>{deliveryStatus}</p>
					<p className="">|</p>
					<p>{date}</p>
					<p className="">|</p>
					<p>{time}</p>
				</div>
			</div>
		</li>
	);
};

const dummyMessages: {
	id: number;
	senderName: string;
	message: string;
	deliveryStatus: string;
	date: string;
	time: string;
	isMe?: boolean;
}[] = [
	{
		id: 1,
		senderName: "Poju Nameki",
		message: "Hello, I am running late!",
		deliveryStatus: "Delivered",
		date: "2024-08-26",
		time: "10:45 AM",
	},
	{
		id: 2,
		senderName: "Bosun Naa",
		message: "No worries, we've moved you by 30 mins",
		deliveryStatus: "Delivered",
		date: "2024-08-26",
		time: "10:47 AM",
		isMe: true,
	},
	{
		id: 3,
		senderName: "Poju Nameki",
		message: "Okay Thanks",
		deliveryStatus: "Delivered",
		date: "2024-08-26",
		time: "10:50 AM",
	},
];
