import React, { useEffect, useLayoutEffect, useState } from "react";
import ScheduleLayout from "../../components/Waitlist/ScheduleLayout";
import { Button } from "@/src/components/ui/button";
import { useNavigate, useParams } from "react-router";
import { useGetScheduleInfo } from "@/src/store/slices/scheduleSlice";
import { ScheduleLocationData } from "@/src/types/schedule";
import useScheduleStore from "@/src/store/useScheduleStore";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import { changeTheme } from "@/src/utils/general";

const Schedule: React.FC = () => {
	const { scheduling_code } = useParams();
	const [onboardingStep, setOnboardingStep] = useState<0 | 1>(0);
	const { setStep, bookingType, setBookingType } = useScheduleStore();
	const navigate = useNavigate();
	const { data, isSuccess, isLoading } = useGetScheduleInfo({
		scheduling_code,
	});
	const {
		setAppointmentLocationDetails,
		setAppointmentDetails,
		setScheduleCode,
		setValidationDetails,
		setValidationCode,
		setAppointmentPatientDetails,
	} = useScheduleStore();
	const isStation: boolean = bookingType == "station";
	const isLocation: boolean = bookingType == "location";
	const isBusiness: boolean = bookingType == "business";
	const isVerificationActive = data?.verification?.is_enabled ? true : false;
	const verificationFormAction = data?.verification?.action_url;
	const verificationFieldTitle = data?.verification?.field_title;
	const verificationActionField = data?.verification?.action_on;

	useEffect(() => {
		setScheduleCode(scheduling_code ?? "");
		setValidationDetails(data?.validators || undefined); //setting validators ahead of the next step
		if (isSuccess && data) {
			if (data && data?.data?.business?.theme) {
				localStorage.setItem("theme", data?.data?.business?.theme);
				changeTheme(data?.data?.business?.theme);
			}
			if (
				data &&
				data?.data?.locations &&
				data?.code_type === "business_code"
			) {
				setBookingType("business");
				setAppointmentDetails(data?.data);
			} else if (data && data?.code_type === "location_code") {
				setBookingType("location");
				setAppointmentDetails(data?.data);
			} else if (
				data?.data.time_slots &&
				data?.code_type === "station_code"
			) {
				setBookingType("station");
				setAppointmentDetails(data?.data);
			} else {
				setAppointmentLocationDetails(
					data.data as unknown as ScheduleLocationData
				);
			}
		}
	}, [
		isSuccess,
		data,
		setScheduleCode,
		scheduling_code,
		setBookingType,
		setAppointmentDetails,
		setAppointmentLocationDetails,
		setValidationDetails,
	]);

	const handleVerificationButtonChoice = (buttonChoice: "yes" | "no") => {
		if (buttonChoice == verificationActionField?.toLowerCase()) {
			return window.open(verificationFormAction);
		}
		handleContinue();
	};

	const handleContinue = () => {
		if (isVerificationActive && onboardingStep != 1) {
			setOnboardingStep(1);
		} else {
			setStep(0);
			navigate(`/schedule/${scheduling_code}/create-appointment`);
		}
	};

	useEffect(() => {
		setAppointmentPatientDetails(null);
		setValidationCode("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		localStorage.setItem("heap-appointment-start-time", "0");
		localStorage.setItem("heap-waitlist-start-time", "0");
	}, []);

	return (
		<>
			<div className="bg-primary-3 h-screen overflow-hidden py-10 font-sans md:pb-0">
				{onboardingStep == 0 && (
					<ScheduleLayout>
						{isSuccess ? (
							<div className="mt-14 h-auto w-full rounded-[12px] bg-white px-6 py-4 md:mt-0 md:max-w-[486px]">
								<h1 className="mb-3 text-[28px] font-semibold text-main-1 md:mb-0 lg:text-32px">
									Welcome!
								</h1>
								{isBusiness && (
									<>
										<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
											<i className="mgc_building_1_line before:!text-primary" />
											<p className="text-sm font-semibold capitalize text-main-1">
												{data?.data?.name}
											</p>
										</div>
										<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
											<i className="mgc_location_line before:!text-primary" />
											<p className="text-sm capitalize text-[#6D748D]">
												{data?.data?.address}
											</p>
										</div>
									</>
								)}
								{isLocation && (
									<>
										<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
											<i className="mgc_building_1_line before:!text-primary" />
											<p className="text-sm font-semibold capitalize text-main-1">
												{data?.data?.name}
											</p>
										</div>
										<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
											<i className="mgc_location_line before:!text-primary" />
											<p className="text-sm capitalize text-[#6D748D]">
												{data?.data?.address}
											</p>
										</div>
									</>
								)}
								{isStation && (
									<>
										<div className="flex flex-col gap-y-2">
											<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
												<i className="mgc_building_1_line before:!text-primary" />
												<p className="text-sm font-semibold capitalize text-main-1">
													{data?.data?.location?.name}
												</p>
											</div>
											<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
												<i className="mgc_location_line before:!text-primary" />
												<p className="text-sm text-[#6D748D]">
													{
														data?.data?.location
															?.address
													}
												</p>
											</div>
											<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
												<i className="mgc_store_line before:!text-[#043B6D]" />
												<p className="text-sm capitalize text-main-1">
													{data?.data?.name}
												</p>
											</div>
										</div>
									</>
								)}
								<div className="mt-10 w-full">
									<Button
										type="button"
										className="w-full bg-primary"
										onClick={handleContinue}
									>
										Continue
									</Button>
								</div>
								{/* )} */}
							</div>
						) : (
							!isLoading && (
								<div className="mt-8  rounded-[12px] bg-white p-4">
									<p className="text-center text-2xl font-semibold">
										No Information found for this code{" "}
									</p>
								</div>
							)
						)}
					</ScheduleLayout>
				)}
				{onboardingStep == 1 && (
					<ScheduleLayout>
						<div className="flex w-full flex-col gap-y-4 md:max-w-[486px] lg:max-w-[600px]">
							{data?.data?.business?.logo && (
								<div className="mx-auto flex  h-[150px] w-full max-w-[95%] flex-col items-center justify-center rounded-lg bg-white px-6 py-4 md:mt-0 ">
									<img
										src={data?.data?.business?.logo}
										alt="business_logo"
										className="mx-auto h-full object-contain"
									/>
								</div>
							)}

							<div className="flex h-auto w-full flex-col items-center justify-center rounded-[12px] bg-white  px-6 py-4 md:mt-0 md:min-h-[313px] ">
								<div className="flex flex-col items-center gap-y-2 text-center">
									<p className="mb-3 text-sm font-semibold text-[#596574] md:mb-0 lg:text-[16px]">
										Welcome!{" "}
										{data?.data?.business?.name || ""}
									</p>
									<h1 className=" mb-3  font-semibold  text-main-1 md:mb-0 ">
										{verificationFieldTitle ||
											"Please select an option"}
									</h1>
								</div>
							</div>
							<div className="flex w-full items-center gap-x-4  ">
								<Button
									type="button"
									className="w-full bg-primary"
									onClick={() =>
										handleVerificationButtonChoice("yes")
									}
								>
									Yes
								</Button>
								<Button
									type="button"
									variant="outline"
									className=" w-full border-primary bg-transparent"
									onClick={() =>
										handleVerificationButtonChoice("no")
									}
								>
									No
								</Button>
							</div>
						</div>
					</ScheduleLayout>
				)}
			</div>
			<RequestIsLoading
				isLoading={isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	);
};

export default Schedule;
