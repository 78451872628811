import { CONFIGS } from "@/src/configs";
import useUserStore from "@/src/store/useUserStore";
import { useEffect } from "react";
import { toTitleCase } from "../utils/general";
import useScheduleStore from "../store/useScheduleStore";

const useHeapAnalyticsIdentify: () => void = () => {
	const heap = (window as any).heap;
	const { user } = useUserStore((s) => ({
		user: s.user,
	}));
	const { appointmentPatientDetails } = useScheduleStore((s) => ({
		appointmentPatientDetails: s.appointmentPatientDetails,
	}));

	const handleHeapAnalyticsIdentify = () => {
		if (!heap) return;
		const heapAppointmentStartTime = parseInt(
			localStorage.getItem("heap-appointment-start-time") ?? "0"
		);
		const heapWaitlistStartTime = parseInt(
			localStorage.getItem("heap-waitlist-start-time") ?? "0"
		);
		if (!heapWaitlistStartTime && !heapWaitlistStartTime) return;
		if (heapAppointmentStartTime > heapWaitlistStartTime) {
			if (appointmentPatientDetails) {
				// console.log(1);
				if (heap.load) heap.load(CONFIGS.HEAP.HEAP_ID);
				heap.identify(appointmentPatientDetails.phone_number);
				heap.addUserProperties({
					name:
						toTitleCase(
							appointmentPatientDetails.full_name ?? ""
						) ?? "",
					phone_number: appointmentPatientDetails.phone_number,
					email: appointmentPatientDetails.email ?? "",
				});
				return;
			}
		}
		if (user) {
			// console.log(2);
			if (heap.load) heap.load(CONFIGS.HEAP.HEAP_ID);
			heap.identify(user.waitlist_info.customer.phone_number);
			heap.addUserProperties({
				name: toTitleCase(user.waitlist_info.customer.full_name ?? ""),
				phone_number: user.waitlist_info.customer.phone_number,
				email: user.waitlist_info.customer.email ?? "",
			});
			return;
		}
	};

	useEffect(() => {
		handleHeapAnalyticsIdentify();
	}, [user, appointmentPatientDetails]);
};

export default useHeapAnalyticsIdentify;
