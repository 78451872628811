/* eslint-disable @typescript-eslint/no-explicit-any */

import { cn } from "@/src/utils/general";
import Select, { Props as SelectProps } from "react-select";

const CustomSelect = ({
	placeholder,
	className,
	height,
	value,
	...props
}: SelectProps & { height?: string }) => {
	const customSelectStyles = {
		control: (defaultStyles, state) => ({
			...defaultStyles,
			"&:active": {
				borderColor: "rgba(var(--primary))",
			},
			minHeight: "34px",
			"&:hover": {
				borderColor: "rgba(var(--primary))",
			},

			boxShadow: state.isFocused
				? `0 0 0 1px rgb(var(--primary))`
				: "none",
			borderColor: state.isFocused ? "rgb(var(--primary))" : "#E8E8E8",
			borderRadius: "8px",
			borderWidth: "0.5px",
		}),
		placeholder: (provided) => ({
			...provided,
			fontSize: "15px",
			fontWeight: 400,
			color: "hsl(var(--muted-foreground))",
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: "hsl(var(--background))",
			zIndex: 9999,
			borderRadius: "8px",
			color: "hsl(var(--foreground))",
		}),
		input: (provided) => ({
			...provided,
			caretColor: "transparent",
			border: "none",
			fontSize: "15px",
			borderWidth: "0.5px",
			"&:disabled": {
				backgroundColor: "rgba(var(--primary))",
			},
		}),
		multiValue: (base) => ({
			...base,
			backgroundColor: "rgba(var(--primary)/0.2)",
		}),
		multiValueLabel: (base) => ({
			...base,
			color: "rgb(var(--primary))",
		}),
		multiValueRemove: (base) => ({
			...base,
			color: "#B1B9C5",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected
				? "rgb(var(--primary))"
				: state.isFocused
					? "rgba(var(--primary)/0.1)"
					: "hsl(var(--background))",
			color: state.isSelected ? "white" : "hsl(var(--foreground))",
			fontSize: "15px",
		}),
	};
	return (
		<div className={cn("", className)}>
			<Select
				// defaultValue={value}
				value={value}
				styles={customSelectStyles}
				className={cn("text-[14px]")}
				placeholder={placeholder}
				{...props}
			/>
		</div>
	);
};

export default CustomSelect;
