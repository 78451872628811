import { toast } from "react-hot-toast";
import { Button } from "./ui/button";

export interface CustomToastProps {
	id: string;
	title?: string;
	type?: "success" | "error" | "loading" | "info";
	duration?: number;
	undoText?: string;
	undoHandler?: () => void;
	position?:
		| "top-left"
		| "top-right"
		| "bottom-left"
		| "bottom-right"
		| "top-center"
		| "bottom-center";
	icon?: any;
}

const useCustomToast = () => {
	return (
		message: string | React.ReactNode,
		{
			id,
			title,
			type = "success",
			duration = 4000,
			undoText,
			position = "top-right",
			icon = true,
			undoHandler,
		}: CustomToastProps
	) => {
		const content = (
			<div className="-my-2 mr-[-20px] flex items-center justify-between">
				<div className="flex items-center space-x-5">
					<div className="flex flex-col gap-y-1 py-2">
						<p
							className={`font-semibold ${type === "error" ? "text-red-600" : "text-green-600"}`}
						>
							{title}
						</p>
						<p className="text-base font-medium text-[#6D748D]">
							{message}
						</p>
					</div>

					{undoText && (
						<Button
							className="h-[34px] w-[117px] bg-[#138576] hover:bg-[#138576]"
							onClick={(e) => {
								e.stopPropagation(); // Prevent the toast from dismissing when the button is clicked
								undoHandler && undoHandler();
							}}
						>
							{undoText}
						</Button>
					)}
				</div>
				<button
					className="h-fit p-2.5"
					onClick={() => toast.dismiss(id)}
				>
					<i className="mgc_close_line" />
				</button>
			</div>
		);

		const options = {
			id,
			duration,
			position,
			icon: icon == false ? null : undefined,
			// Use custom icon if provided, or default, or hide if showIcon is false
		};

		switch (type) {
			case "success":
				toast.success(content, options);
				break;
			case "error":
				toast.error(content, options);
				break;
			case "loading":
				toast.loading(content, options);
				break;
			default:
				toast(content, options);
		}
	};
};

export default useCustomToast;
